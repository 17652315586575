.globalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 40px 0px;

  &.resourceHeader {
    padding: 20px 0 25px;
    border-bottom: 1px solid #dee2e6;
  }

  &.flashcardheader {
    padding: 20px 0 40px 0px;
  }
}

.globalHeader--left,
.globalHeader--right {
  display: flex;
  align-items: center;
}

.globalHeader--right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  // flex-flow: wrap;
  gap: 10px;
}

.margin-bottom-26 {
  margin-bottom: 26px;
}

.margin-bottom-39 {
  margin-bottom: 39px;
}

.custom-fs-11 {
  font-size: 11px;
  font-weight: 400;
}

.custom-fw-bold {
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 26px;
}

.ant-switch {
  min-width: 29px;
  height: 18px;
}

.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 17px);
}

.ant-switch.ant-switch-checked {
  background: #7b68ee;
}

.ant-switch .ant-switch-handle::before {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: "";
  width: 15px;
  height: 15px;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #7b68ee;
}

.main-point-table {
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        padding-top: 21px;
        padding-bottom: 21px;
      }
    }
  }
}

.main-reward-table {
  .ant-table-tbody {
    .ant-table-row {
      height: 65px;
      // .ant-table-cell {
      //   padding-top: 21px;
      //   padding-bottom: 21px;
      // }
    }
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.border-line {
  border-bottom: unset;
  padding-bottom: 0;
}

.gallery-header {
  padding-bottom: 15px;

  .globalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
  }

  p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 15px;
    margin-bottom: 33px;
  }
}

.gallery-link {
  .ant-space {
    gap: 12px !important;
  }
}

.location-desc-padding {
  padding-left: 38px;
  /* padding-top: 24px; */
  padding-right: 38px;
  padding-bottom: 27px;
}

.custom-height-20 {
  line-height: 20px;
}

.custom-fs-15 {
  font-size: 15px;
}

.mt_24 {
  margin-top: 24px;
}

.mt-19 {
  margin-top: 19px;
  font-size: 12px;
}

.custom-dropdown {
  height: 216px;
  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: absolute;
  width: 180px;
  z-index: 4;
  padding-top: 19px;
  padding-left: 24px;
  right: 0;
  top: 45px;

  .sub-dropdown {
    &:not(:first-child) {
      margin-top: 24px;
    }

    span {
      margin-left: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.folder-media-upload {
  height: auto;
  padding-bottom: 19px;
}

.position-realtive {
  position: relative;
}

.disabled-field {
  &.form-control:disabled {
    background-color: transparent;

    &:hover {
      border-color: #ced4da;
    }
  }
}

.lockinput-field {
  max-width: 230px;
  height: 175px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  position: absolute;
  bottom: 60px;
  right: 0;
  padding: 25px 18px;
  text-align: center;
  transition: opacity 0.3s ease-in;
  opacity: 0;

  h5 {
    margin-top: 15px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .drop-icon {
    position: absolute;
    top: 173px;
    right: 50%;
    left: 50%;
  }
}

.hovered {
  transition: opacity 0.3s ease-in;
  opacity: 0.8;
}

.hovered ~ .lockinput-field {
  opacity: 1;
}

.font-size-12 {
  font-size: 12px;
  font-weight: 400;
}

.dashboard_bg_grey {
  background-color: #fafbfc;
  background-image: url("../../images/admin-dashboard-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.modal-backdrop {
  // --bs-backdrop-opacity: 0.75;
}

.custom-select .MuiMenu-paper {
  max-height: 200px;
  /* Set the maximum height for the menu paper */
}

.custom-select .MuiMenu-list {
  max-height: 200px;
  /* Set the maximum height for the menu */
  overflow-y: auto;
  /* Enable vertical scrolling */
  margin-top: 50px;
  /* Adjust this value as needed to move the scroll position */
}

.font-size-13 {
  font-size: 13px;
}

.badge-p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  padding-bottom: 15px;
}

.font-italic {
  font-style: italic;
}

.ant-select-tree-list-scrollbar-thumb {
  right: 0;
  left: 23px !important;
}

.light-grey {
  color: #7f7f88 !important;
}

.ant-switch-handle {
  top: 1px;
}

.min-width-332 {
  min-width: 332px !important;
}

.aa {
  &.gap-3 {
    gap: 0.5rem !important;
  }
}

.box-shadow-none {
  &:focus {
    box-shadow: none;
  }
}

.pd-left-40 {
  padding-left: 40px;
}

.custom-ml-05 {
  margin-left: 0.5rem;
}

.highlight {
  color: #7b68ee;
}

.custom-pl-pb {
  padding-left: 40px;
  padding-bottom: 20px;
}

.custom-mt-2 {
  margin-top: 2rem;
}

.custom-pt-25 {
  padding-top: 25px !important;
}

.custom-mt-10 {
  margin-top: 10px;
}

.custom-mb-2 {
  margin-bottom: 2px;
}

.custom-pl-20 {
  padding-left: 20px;
}
.custom-pr-20 {
  padding-right: 20px;
}

.blue-accent-color {
  color: $blueAccent;
}

.overlay-class {
  background-color: red;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.font-style-normal {
  font-style: normal;
}

.custom-grey-color {
  &::placeholder {
    color: #d2d0d0 !important;
  }

  &:not(:focus)::placeholder {
    color: transparent !important;
  }
}

.ant-select-dropdown {
  &.widthCustom {
    background-color: red;
    min-width: 135px !important;
  }
}

.dateBoxTwo {
  border: 1px solid rgba(162, 159, 177, 0.35);
  border-radius: 4px;
  padding: 12px 14px 6px 14px;
  // width: 152px;
  width: 140px;
  height: 58px;

  span {
    font-size: 12px;
  }

  .ant-select {
    .ant-select-selector {
      border: none;
      background: transparent;
      padding: 0;

      span {
        font-size: 15px;
      }
    }

    .ant-select-arrow {
      top: 0;
    }
  }
}

.ant-select-dropdown {
  &.dateCustom {
    position: relative;
    z-index: 9999;
  }
}

.custom-mb-20 {
  margin-bottom: 20px;
}

.editSelectDate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.datebtndiv {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 0px;

  button {
    margin-bottom: 23px;
    margin-top: 23px;
  }
}

.invite-staff {
  .modal-content {
    width: 600px;
    margin: 0 auto;
  }
}

.hidden {
  display: none;
}

// .ant-select{
//   .ant-select-arrow{
//     top: 25%;

//   }
// }

.border-radius-2rem {
  border-radius: 0.2rem !important;
}

.br-2rem-hover {
  &:hover {
    border-radius: 0.2rem !important;
    background-color: #dfe2e6 !important;
    color: #7f7f88 !important;
  }
}

.globalBlueLinkColor {
  color: #7b68ee !important;
}

// .ant-select-dropdown {
//   &.hoverClass {
//     .ant-select-item-option-content {
//       &:hover {
//         background-color: #f3f3f3 !important;
//       }
//     }
//   }
// }

.custom-fw-400 {
  font-weight: 400 !important;
}

.newMessageModal {
  .userFooter {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
    border-radius: 5px;
    padding: 16px 30px;
  }

  .modal-content {
    max-width: 560px;
    margin: 0 auto;

    textarea {
      padding: 0 16px;
      margin-bottom: 26px;
    }

    button {
      width: 145px;
      min-height: 42px !important;
      padding: 0;
      line-height: 42px;
    }

    label {
      padding: 17px 30px 0 45px;
    }

    .form-floating {
      padding: 0 30px;
    }
  }
}

.bgColorHover {
  .ant-select-item-option-content {
    &:hover {
      // background-color: #f1f3f6;
    }
  }
}

.customClass {
  font-size: 10px !important;
}

.fade {
  transition: none;
}

.custom-ml-1 {
  margin-left: 1px;
}

.purple-border {
  border-color: $blueAccent !important;

  &:hover {
    border-color: $blueAccent !important;
  }
}

.black-border {
  &:hover {
    border-color: #000 !important;
  }
}

.custom-textarea {
  &.form-floating > .form-control::placeholder {
    font-size: 15px;
    color: $lightGray;
  }

  &.form-floating > .form-control:not(:focus)::placeholder {
    color: transparent;
  }
}

.parent-selector {
  .css-dev-only-do-not-override-1vtf12y & {
    .ant-select {
      position: unset; // or position: static;
    }
  }
}

.customDropdown {
  &.form-floating {
    .form-control {
      transition: none;
    }
  }
}

// YourComponent.scss

// Import the Ant Design styles

// Define your custom styles
.myCustomSelect {
  // Styles on hover
  .ant-select-selector {
    &:hover {
      // border-color: black !important;
      border: none !important;
    }
  }

  // Styles when dropdown is opened
  &.ant-select-open {
    .ant-select-selector {
      border-color: $blueAccent !important; // Replace with desired purple color
    }
  }

  // Styles when dropdown is closed
  .ant-select:not(.ant-select-open) {
    .ant-select-selector {
      border-color: gray !important; // Change to gray color
    }
  }

  // Styles when a value is selected
  &.ant-select-selection--single {
    .ant-select-selector {
      &.ant-select-selection-selected,
      &.ant-select-selection--single:not(.ant-select-selection-disabled) {
        border-color: gray !important; // Change to gray color
      }
    }
  }
}

.new-hire {
  &.mt-4 {
    margin-top: 1.3rem !important;
  }
}

.businessDetailDropdown {
  &.ant-select {
    .ant-select-arrow {
      inset-inline-end: 25px;
    }
  }
}

.mainDivLock {
  display: flex;
  align-items: center;

  &.tip-top {
    align-items: self-start;
  }
}

.lock {
  width: 58px;
  /* display: block; */
}

.hover-text {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
  transition: 0.2s ease-in;
  width: 10px;
  display: inline-block;

  svg {
    height: 11px;
    width: auto;
  }
}

.hover-tooltip {
  position: absolute;
  top: 40px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  display: none;
  z-index: 99;
  color: black;
  /* Add any necessary styles for the hover-tooltip span */
}

.hover-text:hover + .hover-tooltip {
  display: inline-block;
  /* Add any necessary styles to show the tooltip */
}

.badgeDiv {
  width: 41px;
  height: 40px;
}

.before-page-data-load-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ant-tabs-tab-disabled {
  cursor: default !important;
}

.custom-pr {
  position: relative;
}

.custom-popover-class {
  &.ant-popover {
    z-index: 7;

    .ant-popover-inner {
      padding: 0;
      box-shadow: none;
    }

    // &.ant-popover-placement-rightBottom{
    //   top: 383.578px !important;

    // }
    // &.ant-popover-placement-rightTop{
    //   top: 183.578px !important;

    // }
  }
}

.imageTable {
  &.customHeightTable {
    height: calc(100vh - 180px);
    overflow: scroll;
  }

  .imageTableWrapper {
    img {
      margin-right: 4px;
      object-fit: cover;
    }
  }
}

.gap-6 {
  margin-left: 6px;
}

.text-container {
  max-height: 5.8em;

  overflow: hidden;
  position: relative;

  &.ellipsis::after {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    padding-left: 10px;
    /* Add some spacing after the ellipsis */
    width: 100px;
    color: red !important;
  }
}

.input-with-icon {
  position: relative;

  &:hover {
    .form-control {
      border-color: #000;
    }
  }

  .form-control:disabled {
  }
}

.floating-label-disabled {
  label {
    &::after {
      background-color: transparent !important;
    }
  }
}

.clear-icon-disabled {
  background-color: transparent !important;
}

.clear-icon {
  // position: absolute;
  // top: 50%;
  // right: 10px;
  // transform: translateY(-50%);
  // cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  padding-left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: #ffffff;
  height: 50px;
  /* vertical-align: middle; */
  /* display: block; */
  /* text-align: center; */
  margin: 0 auto;
  /* justify-content: center; */
  display: flex;
  align-items: center;
}

.mainCounterDiv {
  padding-top: 20px;

  span {
    font-size: 14px;
  }

  .resend-code {
    cursor: pointer;
    font-weight: 510;
  }
}

.main-pricing-modal {
  .modal-dialog {
    max-width: 680px;
  }

  .pricing-modal-content {
    // width: 929px;
    background-color: #f5f6f8;

    .modal-header {
      justify-content: center;
      text-align: center;
      padding: 20px 0 30px;
      background-color: #fff;

      h2 {
        font-size: 34px;
        font-weight: 700;
        margin-bottom: 0;
        padding-bottom: 10px;
      }

      p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .modal-body {
      padding: 34px 20px 38px;
      overflow: hidden;
      background-image: url("../../images/admin-dashboard-bg.png");

      .main-priceListBox {
        min-height: 300px;
        // max-width: 260px;
        border-radius: 8px;
        background: white;
        border: 1px solid rgba(162, 159, 177, 0.35);
        padding: 26px 30px 33px;

        .seatsMainBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 21px;

          h3 {
            margin-bottom: 0;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
          }

          h4 {
            margin-bottom: 0;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
            color: #7b68ee;
            background-color: #ebe8fc;
            border-radius: 90px;
            height: 25px;
            line-height: 25px;
            min-width: 86px;
            text-align: center;
          }
        }

        .priceMonthBox {
          h2 {
            font-weight: 700;
            font-size: 26px;

            .dollar {
              font-size: 26px;
              color: #7b68ee;
            }

            .month {
              font-size: 11px;
              font-style: normal;
              font-weight: 590;
              color: #a8a8a8;
            }
          }
        }

        .listDiv {
          padding-bottom: 20px;

          ul {
            margin-left: 15px;

            li {
              font-size: 14px;
              color: #7f7f88;
              list-style-type: disc;
              padding-bottom: 6px;
              padding-top: 6px;
            }
          }
        }

        .btnDiv {
          button {
            border: 1px solid transparent;
            color: #ffffff;
            font-size: 14px;
            // max-width: 195px;
            height: 40px;
            width: 100%;
            background-color: #7b68ee;

            &:hover {
              background-color: #7b68ee;
              border: 1px solid #7b68ee;
              color: #fff;
            }
          }
        }

        &:hover {
          button {
            background-color: #7b68ee;
            border: 1px solid #7b68ee;
            color: #fff;
          }

          border: 1px solid $blueAccent;
        }
      }

      .swiper {
        overflow: visible;
      }
    }
  }

  .swiper-button-prev {
    background-image: url("../../images/blackleftarrow.png");
    width: 70px;
    height: 70px;

    &::after {
      font-size: 0;
    }
  }

  .swiper-button-next {
    background-image: url("../../images/balckrightarrow.png");
    width: 70px;
    height: 70px;

    &::after {
      font-size: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .modal-dialog {
    max-width: 650px !important;
  }
}

@media only screen and (max-width: 500px) {
  .modal-dialog {
    max-width: auto !important;
  }

  .main-pricing-modal .pricing-modal-content .modal-body .main-priceListBox {
    margin: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .fade.main-pricing-modal.modal.show {
    background-color: white;
  }

  .main-priceListBox {
    button {
      background-color: #7b68ee;
      border: 1px solid #7b68ee;
      color: white !important;
    }

    border: 1px solid #7b68ee;
  }
}

.taskAnchor {
  display: flex;
  align-items: center;
}

.resource-first-step-attachment-uploader .ant-tabs-nav::after {
  border-top: 0px solid !important;
}

.resource-first-step-attachment-uploader .ant-tabs-nav {
  position: unset !important;
  margin: 0 0 10px 0;
}

.form-control {
  &.no-box-shadow {
    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: $border;
    }
  }
}

.form-floating > label {
  z-index: 0;
}

.hasError {
  border-color: red;
  transition: all 1s ease-in;

  // padding: 0 0.75rem;
  label {
    color: red;
  }

  &:focus,
  &:hover {
    box-shadow: none;
    border-color: red;

    label {
      color: red;
    }
  }
}

.truncateDocName,
.truncateTaskName,
.truncate,
.truncateUploadFolderName,
.truncateUploadFolderName2,
.truncateTaskDetailPageHeading,
.truncateQuizDetailPageHeading,
.truncateGroupDetailPageHeading,
.truncateDocNameDetailPage,
.truncateActivityAction,
.truncateDetailPageTitle,
.truncateTaskLink {
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.document-content {
  max-width: 400px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncateUploadFolderName {
  width: 160px;
}

.truncateUploadMediaName {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncateUploadFolderName2 {
  width: 200px;
}

.truncate {
  width: 125px;
}

.truncateActivityAction {
  width: 95%;
}

.truncateGroupDetailPageHeading {
  width: 500px;
}

.truncateDetailPageTitle {
  width: 500px;
}

.truncateTaskDetailPageHeading {
  width: 500px;
}

.truncateQuizDetailPageHeading {
  width: 600px;
}

.truncateTaskLink {
  width: 280px;
  display: inline-block;
}

.truncateTaskName {
  width: 240px;
  display: inline-block;
}

.truncateDocNameDetailPage {
  width: 500px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.invisible {
  visibility: hidden;
}

.customZindex {
  z-index: 9 !important;
}

.main-priceListBox-hovered {
  button {
    background-color: #7b68ee !important;
    border: 1px solid #7b68ee !important;
    color: #fff !important;
  }

  border: 1px solid $blueAccent !important;
}

.main-priceListBox-disabled {
  opacity: 0.6;
  pointer-events: none;
}

.ant-select-single {
  height: auto !important;
}

.delete-modal {
  .modal-content {
    .modal-header {
      border: 0px solid;

      .closeBtn {
        margin-left: 95%;
      }
    }
  }
}

// .form-floating>.form-control-plaintext~label::after,
// .form-floating>.form-control:focus~label::after,
// .form-floating>.form-control:not(:placeholder-shown)~label::after,
// .form-floating>.form-select~label::after {
//   background-color: transparent !important;
// }

.customSvgSizeShareUploadMediaModal {
  svg {
    width: 200px;
    height: 180px;
  }
}

.modal-backdrop.show {
  backdrop-filter: blur(6px);
  background-color: rgb(0, 0, 0, 0.75);
  opacity: 1 !important;
}

.resource-modal.show {
  opacity: unset !important;
}

// .modal-backdrop.show {
//   opacity: 0.5;
// }

.file-box {
  background-color: #f6f7f9;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;

  .file-name {
    font-size: 14px;
    font-weight: 600;
    margin-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
  }
}

.messageBox {
  input.ant-input-disabled {
    background-color: transparent;
  }
}

.myProfile__im__topbar {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  border: 1px solid white;
  margin-right: 10px;
  overflow: hidden;
  position: relative;

  .avatar-wrapper {

    display: contents;
  }
}

.custSelectAddMember {
  .ant-select-selector {
    height: 58px !important;
  }
}

.wrapper-main-chat-emoji-picker {
  position: relative;

  .EmojiPickerReact {
    left: 50% !important;
    top: -420px !important;
    z-index: 100px !important;
  }
}
.loading-icon-center{
  display: flex;
  justify-content: center;
}
.noSearchData {
  text-align: center;
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 120px;
  }

  h5 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: #000;
    margin-bottom: 8px;
    margin-top: 10px;
  }

  h6 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #000;
    margin-bottom: 0;
  }
}

.customUploadHeight {
  .ant-upload {
    display: flex;
    justify-content: center;
  }

  svg {
    width: 600px;
  }
}

.ant-upload-list {
  &.ant-upload-list-picture {
    // height: 66px;
  }
}

.mobileLadningPage {
  background-image: linear-gradient(180deg, #7454ec 0%, #bb00df 100%);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;

  .logo {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // height: 70%;
    padding-top: 50px;

    @media screen and (max-width: 767px) {
      // justify-content: center;
      // align-items: center;
      // height: 100%;
    }

    .subDiv {
      text-align: center;

      img {
        width: 60px;
        text-align: center;

        @media screen and (max-width: 767px) {
          width: 50px;
        }
      }

      h1 {
        font-size: 44px;
        color: #fff;
        font-weight: 590;
        text-align: center;
        margin: 36px 0 25px 0;

        @media screen and (max-width: 767px) {
          font-size: 28px;
        }

        @media (max-width: 300px) {
          margin: 20px 0;
        }
      }

      p {
        max-width: 60%;
        margin: 0 auto;
        color: #fff;
        font-size: 17px;
        padding-bottom: 35px;
        line-height: 1.8;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      a {
        font-weight: 590;
        color: #fff;
        font-size: 14px;
      }
    }

    .landingImage {
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      @media screen and (max-width: 767px) {
        bottom: 55px;
      }

      img {
        max-width: 840px;
        width: 100%;
        height: auto;

        @media screen and (max-width: 767px) {
          // display: none;
        }
      }
    }
  }
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active {
  transition: none !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.activity__list__Description {
  li {
    overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
  }
}

.teamLayoutWrapper {
  .innerBody {
    // &.customHeightProfile {
    //   height: 100%;

    //   @media screen and (max-width: 1366px) {
    //     height: calc(100vh - 75px);
    //   }
    // }

    // height: calc(100vh - 100px);
    // overflow-y: auto;
  }
}

.myProfile__img {
  img {
    object-fit: cover;
  }
}

.surveQuizMultiplImageQuestion {
  position: relative;

  .ant-upload {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;

    svg {
      position: absolute;
      top: 40%;

      path {
        fill: #7b68ee;
      }
    }
  }
}

.event-place-suggestion-wrapper {
  position: relative;

  .suggested-event-location-ul {
    position: absolute;
    z-index: 100;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 4px;
    background-color: white;
    z-index: 10000;

    li {
      padding: 14px 12px;

      &:hover {
        color: #7b68ee;
        cursor: pointer !important;
      }
    }
  }
}

.internal-link-content-dropdown-loading-text {
  font-size: 12px;
  color: #7b68ee;
}

.disable {
  cursor: no-drop;
  pointer-events: none;
  background-color: #ebebe4;
}

.addLinkBox {
  &.addLinkBox-upload-modal {
    .ant-upload-wrapper {
      .ant-upload {
        border: none;
      }
    }
  }
}

.three-type-upload {
  button {
    &.ant-upload-list-item-action {
      opacity: 1 !important;
    }
  }
}

.overflow-ellipses {
  width: 635px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.img-upload-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border: 0 !important;
  margin: 11px 0;
  border: 1px solid #cfcfcf;
  border-radius: 5px;

  .ant-image {
    width: 50px;
    height: 50px;
    overflow: hidden;

    .ant-image-img {
      width: 100% !important;
      height: 100% !important;
      vertical-align: middle;
      object-fit: contain;
    }
  }

  button.delete-img {
    min-width: unset;
    width: auto;
    height: 50px;
    background: unset;
    color: black;
    border: unset;
    padding-right: 0;

    &:hover,
    :active {
      background: unset;
      color: black;
      border: unset;

      svg {
        path {
          fill: #f65160;
        }
      }
    }
  }
}

.galleryCardBox {
  div.galleryBoxImg.responses {
    width: 130px;
    height: 78px;
    padding: 0px;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
}

.message-item-timestamp {
  display: block;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #a8a8a8;
}

.upload-doc {
  display: flex;
  align-items: flex-end;
}

.zero-step {
  .ant-select-selection-search {
    margin-inline-start: 4px;
  }
}

.tickets-datatable {
  .ant-table-row {
    cursor: pointer;
  }
}

.share-modal-without-image.modal-body {
  padding: 40px 38px 25px 38px !important;
}

.ant-pagination {
  .ant-pagination-item-active {
    border-color: #cfcfcf;

    &:hover {
      border-color: #cfcfcf;

      a {
        color: #000;
      }
    }
  }
}

.ant-picker-focused.ant-picker {
  border-color: unset;
  box-shadow: unset;
  outline: unset;
}

.ant-dropdown-trigger.icon.cursor-pointer.disabled-true {
  cursor: not-allowed;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: #7b68ee;
}

a.black.disabled-true {
  cursor: not-allowed;

  &:hover {
    color: #000;
  }
}

.message-content a {
  color: #ffffff;
  text-decoration: underline;
  font-weight: 500;
}

/// === wrapper of the profile pages ===
.profileBody,
.profileBodyWrapper {
  height: calc(100vh - 230px);
  overflow: auto;
}

button.ant-picker-year-btn,
button.ant-picker-month-btn {
  text-decoration: underline dotted;
}

.avatar-characters {
  background-color: #a8a8a8;
  color: white;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.group-members{
  border: 1px solid #fff;
}

.avatar-border--white{
  border: 1px solid #fff;
}

.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-name {
  color: #7b68ee;
}

.upload-dashed-border-image {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%237B68EEFF' stroke-width='3' stroke-dasharray='3%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
}

.customUploader {
  width: 100%;
  .customUploadOverlay {
    display: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100vw;
    height: 100vh;
    // background: url("../../images/uploader-overlay-bg.png") rgba(#fff, 0.9) no-repeat center;
    opacity: 0.9;
    background-color: white;
    background-size: cover;

    .overlayLogo {
      width: 40px;
      height: 40px;
      background: url("../../images/uploader-overlay-logo.png") no-repeat center;
      background-size: contain;
    }

    .overlayText {
      margin-bottom: 60px;
      font-weight: 600;
      font-size: 20px;
    }

    .overlayName {
      display: inline-block;
      margin-left: 8px;
      color: #fff;
      text-shadow:
        2px 0 2px #7b68ee,
        -2px 0 2px #7b68ee,
        0 2px 2px #7b68ee,
        0 -2px 2px #7b68ee,
        1px 1px 1px #7b68ee,
        -1px -1px 1px #7b68ee,
        1px -1px 1px #7b68ee,
        -1px 1px 1px #7b68ee;
    }
  }
}

.customUploader.dragIn {
  .customUploadOverlay {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .ant-upload.ant-upload-select {
    position: relative;

    input {
      display: block !important;
      position: absolute;
      z-index: 99;
      opacity: 0;
      top: 50% !important;
      left: 50% !important;
      transform: translateX(-50%) translateY(-50%) !important;
      width: 100vw !important;
      height: 100vh !important;
    }
  }
}

.modal-content {
  overflow: hidden !important;
}

.uploadIconPdfView {
  margin-right: 8px;
}

.uploadCloud {
  width: 70px;
  height: 70px;
}
