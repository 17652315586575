.refferal-header {
  margin-bottom: 27px;
  .refferal-title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .refferal-desc {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
  .refferal-desc a {
    color: #7b68ee;
  }
}
.refferal-row {
  display: flex;
}
.refferal-container {
  border: 1px solid rgba(162, 159, 177, 0.35);
  border-radius: 5px;
  /* padding: 20px 50px 20px 20px; */
  width: 290px;
  padding-top: 21px;
  padding-left: 29px;
  padding-right: 41px;
  padding-bottom: 34px;
  margin-right: 21px;
  .one {
    color: #7b68ee;
    font-size: 24px;
    margin-bottom: 0;
    padding-bottom: 16px;
  }
  .two {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 11px;
    margin-bottom: 0;
  }
  .three {
    color: #7f7f88;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 0;
  }
  .second {
    color: #ff519a;
  }
  .third {
    color: #00b000;
  }
}
.refferal-table {
  padding-top: 63px;
}
.refferal-table-header p {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.refferal-table-body {
  p {
    color: #000000;
    font-size: 12px;
    font-weight: 400;
  }
}
.refferal-table-name {
  font-weight: 510;
  font-size: 13px;
}
