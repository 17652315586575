.ant-notification {
  z-index: 1500;

  .ant-notification-notice-wrapper {
    width: 372px;
    border-radius: 0px !important;
    background: transparent;
    box-shadow: unset;
  }

  strong,
  .ant-notification-notice-description {
    font-size: 15px !important;
  }
}
.ant-drawer-wrapper-body {
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 99px;
    pointer-events: auto;
    //background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 212, 255, 0) 100%);
    @media screen and (max-width: 1366px) {
      height: 100px;
    }
  }
}

.notificationAnchor {
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e6e8;
}

.notificationCard {
  display: flex;

  &.cleared {
    .notificationContent {
      width: 70%;
    }

    .clearedIcon {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      margin-top: 5px;

      svg {
        path {
          fill: $blueAccent;
        }
      }
    }
  }

  .notificationAvatar {
    margin-right: 16px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .notificationTime {
    margin-top: 0px;

    span {
      color: $gray2;
      font-weight: 400;
      line-height: 1;
      font-size: 12px;
    }
  }

  .notificationText {
    p,
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
    }

    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      color: $black;

      span {
        font-weight: 400;
      }
    }
  }
}

.messageAnchor {
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e6e8;
}

.messageCard {
  display: flex;
  justify-content: space-between;
  .notificationContent {
    display: flex;
    gap: 22px;
    .notificationAvatar {
      margin-top: auto;
      margin-bottom: auto;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .mainContent {
      .notificationText {
        p,
        h1,
        h2,
        h3,
        h4,
        h6 {
          margin: 0;
        }

        h6 {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $black;
        }
      }
      .notificationTime {
        margin-top: 0px;

        span {
          color: #a8a8a8;
          font-weight: 400;
          line-height: 1;
          font-size: 12px;
        }
      }
      .notificationSubText {
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #717171;
          margin-bottom: 0;
        }
      }
    }
  }
  .hide {
    display: none;
  }
  .countDiv {
    background-color: #7b68ee;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    font-style: normal;
    font-weight: 590;
    font-size: 10px;
    line-height: 22px;
    color: #ffffff;
  }
  &.cleared {
    .notificationContent {
      width: 70%;
    }

    .clearedIcon {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      margin-top: 5px;

      svg {
        path {
          fill: $blueAccent;
        }
      }
    }
  }
}

.ant-notification-notice {
  background: white;
  box-shadow: unset;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
