.tableWrapper {
  display: none;

  &.active {
    display: block;
  }

  .ant-table-content {
    .ant-table-thead {
      .ant-table-cell {
        &.ss {
          .ant-table-column-sorters {
            justify-content: center;
          }
        }
      }
    }
  }
}

.nobg_table {
  .ant-table-tbody {
    .ant-table-row {
      height: 77px;

      &:nth-child(even) {
        background: transparent;
      }

      .ant-table-cell {
        padding: 10px 0px;
      }
    }
  }
}

//Table Body Styling
.ant-table-tbody {
  .ant-table-row {
    &:nth-child(even) {
      background: rgba(240, 242, 245, 0.35);
    }

    .ant-table-cell {
      //change to 0 due to table alignment issues
      // padding: 11px 16px;
      padding: 11px 0;

      p {
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: flex-start;
        font-size: 13px;
        font-weight: 400;
        color: $black;
        line-height: 18px;
        text-align: left;
        margin: 1px 0 0 auto;

        &.subline {
          font-size: 12px;
          color: $gray2;
          display: flex;
          align-items: center;
          flex-flow: row;
          justify-content: flex-start;

          span {
            font-size: 12px;

            &.blue-accent {
              color: $blueAccent;
            }

            color: $gray2;
          }
        }

        span {
          font-size: 10px;
          color: $gray2;
          align-self: stretch;
        }
      }

      &:first-child {
        font-size: 13px !important;
        font-weight: 600;
      }

      &.ant-table-column-sort {
        background-color: transparent !important; //when the table sort is opened
      }

      &.ant-table-cell-row-hover {
        background-color: transparent !important;
      }

      .ant-space {
        .ant-space-item {
          font-size: 13px !important;
          font-weight: 600;

          span.ant-avatar {
            min-width: 40px;
            min-height: 40px;
          }
        }
      }
    }
  }
}

//Table Header Styling
.ant-table-wrapper {
  .ant-table-column-sorter-up {
    display: none;
  }

  .ant-table-column-sorter-down {
    margin-top: 0 !important;

    &.active {
      color: $blueAccent;
    }
  }

  .center-column {
    .ant-table-column-sorters {
      justify-content: center;
    }
  }

  .ant-table-column-sorters {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ant-table-column-title {
      flex: unset;
      font-size: 12px;
      font-weight: 600;
      color: $black;
      // font-family: "SF Pro", sans-serif;
    }

    .ant-table-column-sorter {
      display: flex;
    }
  }

  .ant-table-thead > tr > td {
    background-color: #ffffff;
  }

  .ant-table-thead > tr > th {
    background: none;
    font-size: 12px;
    font-weight: 600;

    &.ant-table-cell {
      padding: 5px 0px;
      background: transparent;

      // font-family: "SF Pro", sans-serif;
      &:before {
        content: normal !important;
      }

      &:hover {
        background: transparent;
      }
    }
  }
}

//Table meet ball
.ant-dropdown-trigger {
  &:hover {
    svg {
      path {
        fill: #000 !important;
      }
    }
  }
}

.checkmarkdiv {
  cursor: pointer;
  user-select: none;

  svg {
    &:hover {
      path {
        fill: $blueAccent;
      }
    }
  }
}

.image-container {
  height: 42px;
  width: 42px;

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}

.eventDetailImage {
  &.svg-size {
    width: 46px;
    height: 46px;
  }

  width: 70px;
  height: 51px;
  border-radius: 3px;
  object-fit: cover;
  margin-right: 4px;
}

.squareAvatar {
  width: auto;
  text-align: center;
  padding: 0px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.activityAvatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

//Remove the blinking tab
.ant-tabs-nav-operations {
  display: none !important;
}

.dottedRoundedBox {
  border: 1px dashed $gray2;
  width: 35px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: $black;
  font-weight: 600;
  // margin-right: 15px;
}

.attendeeNames {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  min-width: max-content;

  span,
  strong {
    font-size: 12px;
    line-height: 20px;
  }
}

.taskHead {
  width: 96px;
  height: 25px;
  color: white;
  font-size: 11px;
  display: block;
  padding: 0;
  line-height: 23px;
  margin: 0 auto;
  border-radius: 3px 3px 0px 0px;
  margin-bottom: -5px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flashCardTable {
  .taskHead {
    text-transform: capitalize;
  }
}

.customSvgSize {
  svg {
    width: 46px;
    height: 46px;
  }
}

.ant-spin-nested-loading {
  height: calc(100vh - 150px);
}

.chat-messages .ant-spin-nested-loading {
  height: calc(100vh - 270px);
}

.loader-table.ant-spin-dot {
  position: absolute !important;
  top: 40% !important;
  inset-inline-start: 45% !important;
  margin: -10px !important;
  width: 100px !important;
  height: 100px !important;
}

.ant-spin-blur tbody.ant-table-tbody {
  opacity: 0;
}

.owenerLabel {
  display: flex;
  align-items: center;
  margin: 0;
  gap: 7px;

  h6 {
    font-size: 12px;
    color: $black;
    font-weight: 600;
    margin: 0;
  }
}

.activityTable,
.lazyLoadTable {
  .ant-table-header.ant-table-sticky-holder {
    border-radius: 0;
  }
  .ant-table-body {
    min-height: 200px;
  }
}
