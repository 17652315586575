.OtpInput-container {
  margin-bottom: 32px;

  > div {
    width: 100%;
    justify-content: space-between;
  }

  .OtpInput {
    input {
      width: 98px !important;
      height: 65px;
      border: 1px solid $lightGray;
      border-radius: 5px;

      &:focus,
      &:focus-visible {
        outline: none;
        border-color: $blueAccent;
      }
    }
  }
}

.passCodeLine {
  margin-top: 70px;
}
