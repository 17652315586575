.CustomeMemberSelect {
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid $black;

    .ant-select-selector {
      border-color: transparent !important;
    }
  }

  &:focus {
    border: 1px solid $blueAccent;
  }

  .ant-select-selector {
    border: 1px solid $border !important;
    border-radius: 4px !important;

    .ant-select-selection-item {
      margin: 0;
      padding: 0;
      line-height: 1;
      background: transparent;
      border: 0;
      .ant-select-selection-item-content {
        font-size: 15px;
        // font-family: "SF Pro Regular";
        font-weight: 400;
        color: $black;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        padding-inline-end: 0px;
      }
    }
  }
  .ant-select-arrow {
    .ant-badge {
      .ant-badge-count {
        background: $blueAccent !important;
        color: white !important;
        border-color: $white !important;
        box-shadow: none !important;
      }
    }
  }
  .ant-select-clear {
    display: none;
  }
}

.customMemberItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .unchecked {
    color: $lightGray;
    font-size: 16px;
  }
  .checked {
    font-size: 16px;
  }

  .memberName {
    h4 {
      font-weight: 400;
    }
  }
}

.customMemberBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 5px 5px;
  padding: 13px 15px;
  button {
    min-height: 38px;
  }
}

.customMemberOvlay {
  .rc-virtual-list-holder {
    padding-bottom: 320px;
  }
}
