.treeSelectOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgb(204, 66, 66);
  display: none;

  &.open {
    display: block;
  }
}

.subChildSelectWrapper {
  position: relative;
  // width: 214px;
  margin: 0 auto;
  z-index: 9999;

  .accordianTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    color: $black;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
    }

    span {
      font-weight: 400;
      font-size: 15px;
    }
  }

  .accordianChildList {
    display: none;
    margin: 15px 0 20px 15px;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: 400;
        font-size: 15px;
      }
    }
  }

  .accordianChildListOpen {
    display: block;
    margin: 15px 0 20px 15px;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: 400;
        font-size: 15px;
      }
    }
  }

  .subChildLabelHolder {
    // min-width: 214px;
    // width: 214px;
    height: 58px;
    // border: 1px solid rgba(162, 159, 177, 0.35);
    border: 1px solid #ced4da;

    // box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 5px;
    position: relative;

    &:hover {
      border-color: #000;
    }

    &:focus {
      border-color: red;
    }
  }

  .subChildItemsWrapper {
    position: absolute;
    top: 54px;
    left: 0;
    bottom: auto;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: auto;
    transition: 0.5s ease;
    display: none;

    .acordionChild {
      padding: 19px 23px;

      .subChildBox {
        margin-bottom: 17px;

        .accordianChildListOpen {
          height: 135px;
          overflow: auto;
        }

        &:last-child {
          margin-bottom: 0;
          padding-top: 5px;

          .accordianChildList {
            margin-bottom: 0;
          }
        }

        .valuee {
          display: block;
          width: 120px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    &.open {
      display: block;
      overflow: auto;
    }
  }
}

.labelHeadingBox {
  height: 100%;
  padding: 13px 14px;

  &.added {
    .labelTitle {
      .title {
        font-size: 12px;
        // color: $blueAccent;
        color: $gray2;
        margin-bottom: 4px;
      }

      .highlighted {
        color: $blueAccent;
      }

      .label {
        color: #000;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }

  .labelTitle {
    flex: 1;
    margin-bottom: 0;

    .title {
      margin-bottom: 0;
      color: $gray2;
      font-size: 15px;
      font-weight: 400;
    }

    .label {
      margin-bottom: 0;
    }
  }

  .labelImage {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

//----------- Checkbox ---------
.ant-checkbox-wrapper,
.ant-radio-wrapper {
  flex-flow: row-reverse;
  flex-flow: row-reverse;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 0;

  &:hover {
    .ant-radio-inner {
      border-color: $gray2;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $blueAccent;
      background-color: $white;
    }
  }

  .ant-radio-inner {
    &::after {
      background-color: $blueAccent;
      width: 20px;
      height: 20px;
      margin-block-start: -10px;
      margin-inline-start: -10px;
    }
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      &:after {
        // inset-inline-start: 33%;
        inset-inline-start: 30%;
        width: 4px;
        height: 7px;
        border-width: 1px;
      }
    }
  }

  .ant-checkbox,
  .ant-radio {
    + span {
      flex: 1;
      padding: 0;
      line-height: 1;
      color: $black;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ant-radio-input {
      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
  }
}

.coloured {
  color: $blueAccent !important;
}

.customCounter {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  margin-bottom: 0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7b68ee;
  font-size: 10px;
  overflow: hidden;
  font-weight: 600;
  opacity: 0;
  transform: translateY(100%);
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}

.showCounter {
  opacity: 1;
  transform: translateY(0);
}

.custom-tree-select {
  height: 59px;
  position: relative;
  z-index: 1;

  .form-floating {
    .form-control {
      // padding-top: 30px !important;
      height: 100% !important;
      padding: 1.66rem 0.73rem 0.66rem 0.73rem !important;
    }
  }

  .counter {
    position: absolute;
    right: -6px;
    top: -3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $white;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
  }

  span {
    font-size: 15px;
    font-weight: 400;
    color: $black;

    &:hover {
      color: #7b68ee;
      cursor: pointer;
    }
  }

  .main-wrapper {
    width: 100%;
    position: absolute;
    top: 54px;
    left: 0;
    bottom: auto;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 19px 23px;
  }

  .form-check-input[type="radio"] {
    border-radius: 50%;
    box-shadow: none;
    position: relative;
    margin-top: 4px;
    margin-right: 0;
    border: 1px solid #cfcfcf;
    width: 16px;
    height: 16px;

    &::after {
      display: none;
    }

    &:checked,
    &:focus {
      box-shadow: none;
      background-color: $white;
      border-color: $blueAccent;
      background-image: unset;

      &::after {
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        background: $blueAccent;
        content: "";
        border-radius: 50%;
        top: 3px;
        left: 3px;
      }
    }
  }

  .form-check-input[type="checkbox"] {
    margin-top: 4px;
    margin-right: 0;
    border: 1px solid #cfcfcf;
    width: 16px;
    height: 16px;

    &:checked {
      background-size: 8px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.27503 0.0658127C8.31549 0.0219376 8.35595 0 8.43688 0C8.49758 0 8.55828 0.0219376 8.61897 0.0658127L9.18548 0.702001C9.22594 0.745876 9.26641 0.811688 9.26641 0.877501C9.26641 0.965251 9.22594 1.03106 9.18548 1.07494L3.11578 7.6562C3.07531 7.70007 3.01462 7.72201 2.95392 7.72201C2.87299 7.72201 2.81229 7.70007 2.77183 7.6562L0.0809293 4.71657C0.0202323 4.67269 0 4.62882 0 4.54107C0 4.47526 0.0202323 4.40944 0.0809293 4.34363L0.647435 3.72938C0.687899 3.6855 0.748596 3.64163 0.829526 3.64163C0.890223 3.64163 0.95092 3.6855 0.991385 3.72938L2.95392 5.85732L8.27503 0.0658127Z' fill='white'/%3E%3C/svg%3E%0A");
    }

    &::after {
      display: none;
    }
  }

  .dropdown-listing {
    max-height: 205px;
    overflow-y: auto;

    .ant-checkbox {
      margin-left: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }

    .form-check-input[type="checkbox"] {
      border-radius: 50%;
      box-shadow: none;
      // border-color: $black;

      &:focus {
        box-shadow: none;
      }

      &:checked {
        box-shadow: none;
        background-color: $darkPurple;
        border-color: $darkPurple;
      }
    }
  }

  .hide-radio {
    display: none;
  }
}
