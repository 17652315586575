.main-delete-member {
  .modal-content {
    padding: 58px 53px 65px 53px;
    max-width: 600px;
    margin: 0 auto;

    .modal-header {
      padding: 0;
      border: 0;
      .closeBtn {
        position: absolute;
        right: 28px;
        top: 29px;
        cursor: pointer;
      }
    }
    .modal-body {
      text-align: center;
      padding: 0;
      h4 {
        font-size: 26px;
        line-height: 36px;
        font-weight: bold;
        margin-bottom: 0;
        padding-bottom: 20px;
        padding-top: 46px;

        span {
          color: #ff0054;
        }
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0;
        line-height: 28px;
        padding-bottom: 44px;
      }
    }
    .btn-div {
      display: flex;
      justify-content: center;
      gap: 25px;
    }
  }
}
