.copy-link-container {
  display: flex;
  align-items: center;
  width: 100%;

  input {
    flex: 1;
    border-radius: 5px 0 0 5px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 400;
    font-size: 14px;
    &:focus {
      box-shadow: none;
      border: 1px solid #7b68ee;
    }
  }

  button {
    width: 167px;
    border-radius: 0 5px 5px 0;
  }
}

.copy-link-container-two {
  display: flex;
  align-items: center;
  width: 100%;

  input {
    flex: 1;
    border-radius: 5px 0 0 5px;
    color: $black;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #7b68ee;
    &:focus {
      border: 1px solid #7b68ee;
      box-shadow: none;
    }
  }

  button {
    width: 167px;
    border-radius: 0 5px 5px 0;
    svg {
      margin-right: 11px;
    }
  }
}
