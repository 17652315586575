.eventDetailHeader {
  padding: 13px 43px;
  align-items: center;
  border-bottom: 1px solid $paleGray;
  // height: 57px;

  .sec-heading {
    font-size: 20px;
    line-height: 23px;
  }
}

.leftEventDetailWrapper {
  .eventDetailHeader {
    padding: 22px 40px;
    position: relative;
    z-index: 8;
    background-color: white;
  }
}

.rightEventDetailWrapper {
  border-left: 1px solid $paleGray;

  .eventDetailHeader {
    padding: 19px 43px;
  }
}

.eventDetailBody {
  height: calc(100vh - 150px);
  overflow: auto;

  &.galleryDetailPage {
    height: calc(100vh - 230px);

    @media (min-width: 1400px) {
      height: auto;
    }
  }

  &.noScroll {
    height: auto;
    overflow: unset;
  }

  .sec-heading {
    // padding: 35px 0 30px 0;
    padding: 35px 0 35px;
    color: $black;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    width: 100%;
    max-width: 600px;
    margin-right: 20px;
  }
}

.goalDetailBody {
  height: calc(100vh - 150px);
  overflow: auto;

  .sec-heading {
    padding: 35px 0 30px 0;
  }

  .response-card {
    .response-header {
      border-bottom: 1px solid #e9ecef;
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      padding-right: 40px;

      P {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        padding-bottom: 18px;
      }
    }
  }
}

.backToEvent {
  a {
    color: $blueAccent;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
      width: 16px;
      height: 16px;
    }
  }
}

.eventDetailInformation {
  width: 502px;
  overflow: auto;

  .eventDetailItem {
    margin-bottom: 28px;
    display: flex;
    align-items: flex-start;
    word-break: keep-all;
    * {
      margin-bottom: 0;
    }

    a {
      width: 80%;
    }

    .head {
      color: $black;
      font-size: 13px;
      font-weight: 700;
      color: #000000;
      min-width: 22%;
      line-height: 1.5;

      &.hire {
        color: #7f7f88;
      }
    }

    p {
      font-size: 13px;
      font-weight: 400;
      color: $black;
      line-height: 1.5;

      &.purple-link {
        color: $blueAccent;
      }
    }

    a {
      color: $blueAccent;
      font-size: 13px;
      line-height: 1.5em;
    }

    .recipt-div {
      display: flex;
      align-items: center;
      gap: 9px;

      .single-assignee {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 5px;
      }

      .groupNames {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0;

          &:not(:last-child) {
            margin-right: 4px;
          }
        }
      }

      .icon {
        width: 30px;
        height: 30px;
        padding: 1px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .imgdiv {
        overflow: hidden;
        border-radius: 50%;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

// ------------------------------ Event Response --------------------------------//

.response-card {
  margin-left: 43px;
  margin-top: 30px;

  .response-header {
    border-bottom: 1px solid $paleGray;
    margin-bottom: 16px;

    h6 {
      font-weight: 600;
      font-size: 13px;
    }
  }

  .avatar-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    p {
      font-size: 13px;
      font-weight: 400;
      color: $black;
      margin: 0 0 0 10px;
    }
  }

  .response-body {
    .alert-back {
      font-size: 13px;
    }

    .avatar-item {
      .ant-avatar {
        background: none;
      }
    }

    @media screen and (max-width: 1366px) {
      .customHover {
        .avatar-overlay-two {
          width: 160px;

          .avatar-buttons {
            button {
              width: 100px;
            }
          }

          .triangleToolTip {
            left: -10.8%;
          }
        }
      }
    }
  }
}

.removeBtn {
  .ant-input-group-addon {
    display: none;
  }
}

.eventSearchBar {
  margin-bottom: 18px;

  .ant-input-prefix {
    margin-right: 13px;
  }

  .ant-input-group {
    .ant-input-affix-wrapper {
      height: 50px;
      border-radius: 4px;
      border-start-end-radius: 5px;
      border-end-end-radius: 5px;
      border-start-start-radius: 5px;
      border-end-start-radius: 5px;

      &:hover,
      &:active,
      &:focus {
        border-color: $blueAccent;
        box-shadow: none;
      }
    }
  }
}

.galleryCardBox {
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(124px, 1fr)); */
  /* grid-gap: 1rem; */
  height: 267px;
  overflow: auto;
  position: relative;
  padding-right: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-right: -22px;

  .galleryBoxImg {
    position: relative;
    width: 19%;

    &:hover {
      cursor: pointer;
    }

    &.active {
      .checkIcon {
        color: $blueAccent;
        opacity: 1;
        visibility: visible;
      }
    }

    .checkIcon {
      position: absolute;
      top: 9px;
      left: auto;
      height: inherit;
      display: inline-flex;
      opacity: 0;
      visibility: hidden;
      right: 11px;

      svg {
        width: 2em;
        height: 2em;
      }
    }
  }
}

.folderHeader {
  //Folder Header
  display: flex;
  align-items: center;
  padding-bottom: 26px;
  padding-top: 20px;

  h4 {
    margin: 0;
    color: $black;
  }

  span {
    padding: 0 10px;
    font-weight: 600;
  }
}

.folderHolder {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(207px, 1fr));
  grid-gap: 1rem;

  .folderBox {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $lightGray;
    padding: 13px;

    p {
      margin: 0;
      font-weight: 600;
      margin-left: 10px;
    }

    &:hover {
      border-color: $blueAccent;
      cursor: pointer;
    }
  }
}

// Add members popover
.list__bottom__tooltip {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 13px;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 5px 5px;
  transform: translate(0%, 0%);
}

// Upload Images
.ant-upload-wrapper {
  .ant-upload-list {
    &.ant-upload-list-picture {
      .ant-upload-list-item {
        padding: 0;
        margin: 4px 0 8px;
        border: 0;

        .ant-upload-list-item-thumbnail {
          max-width: 70px;
          max-height: 70px;
        }
      }
    }
  }
}

.ant-upload-list-item {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  border: 0;
}

.upload__image__section {
  padding: 32px 0px 27px 0px;
  display: flex;
  align-items: center;
  gap: 26px;
}

.upload__photo__main {
  min-width: 64px;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background: linear-gradient(180deg, #7b68ee 0%, #b878ff 100%);
  border: 3px solid #f1f3f6;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  .img-div {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .camera__alternate {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Add members
.add__member__action__header {
  display: flex;
  align-items: center;
  gap: 19px;
  padding: 32px 0px 17px;
}

.add__members__title {
  margin-bottom: 0px;
  color: $blueAccent;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.add__member__button {
  width: 100%;
  font-size: 15px;
  padding: 12px 0px;
  min-height: 45px;
}

.members__remove__from__list {
  color: $negativeRed;
  font-size: 10px;
  cursor: pointer;
  font-weight: 400;
}

.member__render__list__inner {
  display: flex;
  align-items: center;
  gap: 19px;

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

.group__details__header {
  position: relative;
}

.group__details__header::after {
  content: "";
  border: 0.5px solid #cfcfcf;
  width: 100%;
  height: 0px;
  display: block;
  position: absolute;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7b68ee;
  border-color: #7b68ee;
  border-radius: 50%;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #7b68ee;
  border-color: #7b68ee;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-1vtf12y).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: #7b68ee;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: transparent;
  content: normal;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 50%;
}

.add_new_popup_list {
  .ant-list {
    .ant-spin-nested-loading {
      height: 325px;
    }

    .ant-list-item {
      padding: 10px 0;

      .ant-list-item-meta {
        align-items: center;

        .ant-list-item-meta-content {
          h4 {
            margin-bottom: 0;
            height: 100%;

            p {
              margin-bottom: 0px;
              display: block;
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  &.member-add {
    .ant-list {
      .ant-list-item {
        &:last-child {
          //padding: 10px 10px 18px 10px !important;
        }
      }
    }
  }
}
