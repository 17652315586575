//Global Color Classes

.purple-accent {
  color: $blueAccent;
}

.pink {
  color: $pink;
}

.blue-accent {
  color: $blueAccent;
}

.aqua-accent {
  color: $aquaAccent;
}

.white {
  color: $white;
}

.light-gray {
  color: $lightGray;
}

.gray {
  color: $gray;
}

.black {
  color: $black;
}

.dark-pink {
  color: $darkPink;
}

.dark-purple {
  color: $darkPurple;
}

.gray-2 {
  color: $gray2 !important;
}

.green {
  color: $green;
}

.negative-red {
  color: $negativeRed;
}
.grayLight {
  color: rgba(0, 0, 0, 0.5);
}
.newgray {
  color: #a8a8a8;
}
//Classes for background
.bg {
  background-color: $bg;
}

.bg-red {
  background-color: $negativeRed;
}

.bg-green {
  background-color: $green;
}

.bg-purple {
  background-color: $blueAccent;
}

.global-bg {
  background-color: $globalBg;
}

.bg-gray4 {
  background-color: $secondarygrey !important;
}

.bg-gray5 {
  background-color: #f5f6f8 !important;
}

//Utilities
.mb-20 {
  margin-bottom: 20px;
}

//Underline Hover Effect
.uLineHover {
  cursor: pointer;
  position: relative;
  display: inline-block;
  color: $blueAccent;
  padding-bottom: 2px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: $blueAccent;
    transition: 0.5s ease-in-out;
  }

  &:hover {
    color: $blueAccent;
    cursor: pointer;

    &:after {
      width: 100%;
    }
  }

  // &.redLine {
  //   color: $negativeRed;

  //   &:after {
  //     background: $negativeRed;
  //   }
  // }

  // &.faded {
  //   color: $gray2;

  //   &:hover {
  //     color: $gray2;
  //   }

  //   &:after {
  //     background: $gray2;
  //   }
  // }
}

.uLine {
  cursor: pointer;
  // position: relative;
  // display: inline-block;
  // color: $blueAccent;
  // padding-bottom: 2px;

  // &:after {
  //   content: "";
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 0;
  //   height: 1px;
  //   background: $blueAccent;
  //   transition: 0.5s ease-in-out;
  // }

  // &:hover {
  //   color: $blueAccent;
  //   cursor: pointer;

  //   &:after {
  //     width: 100%;
  //   }
  // }

  // &.redLine {
  //   color: $negativeRed;

  //   &:after {
  //     background: $negativeRed;
  //   }
  // }

  // &.blackLine {
  //   color: $black;

  //   &:hover {
  //     color: $black;
  //   }

  //   &:after {
  //     background: $black;
  //   }
  // }

  // &.faded {
  //   color: $gray2;

  //   &:hover {
  //     color: $gray2;
  //   }

  //   &:after {
  //     background: $gray2;
  //   }
  // }
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.aa-tooltip {
  width: 75px;

  span {
    display: block;
    // font-family: "SF Pro Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #ffffff;
  }

  .custom-weight {
    font-weight: 600;
  }
}

.custom-white {
  background-color: white;
}

.custom-margin-avatar {
  margin-left: 10px;
}

.blackLine {
  color: $black;

  &:hover {
    color: $blueAccent;
    svg {
      path {
        fill: $blueAccent;
      }
    }
  }
}

.mb-15 {
  margin-bottom: 15px;
}
.deleteRed {
  color: #cfcfcf;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: #ff0054;
  }
}
.borderTopShadow {
}
.custom-height {
  min-height: 74px;
}
