.iconbox {
  display: flex;
  align-items: center;

  .iconRoundedBox {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $paleWhite;
    margin-right: 11px;
    border-radius: 100%;
    position: relative;

    &.StepActive {
      position: relative;
      z-index: 50;
      background: white;
    }

    svg {
      path {
        transition: 0.5s;
      }
    }

    &:hover {
      cursor: pointer;

      svg {
        path {
          &:first-child {
            fill: $blueAccent;
          }
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    span {
      position: absolute;
      top: 5px;
      right: 8px;

      &.chatDot {
        top: 5px;
        right: 8px;
      }
    }
  }
}

.topBarSearchBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .searchBoxes {
    flex: 0 0 67%;
    //display: flex;
    //justify-content: space-between;
    @media screen and (max-width: 1440px) {
      flex: 0 0 67%;
    }
    @media screen and (max-width: 1366px) {
      flex: 0 0 65%;
    }
  }
}

.topBarAccountBox {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.topBarAccountBox {
  display: flex;

  .inner__box {
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;

    .account__owner__titles {
      h3 {
        font-size: 14px;
        color: $black;
        margin-bottom: 0px;
        line-height: 18px;
        font-weight: 600;
      }

      p {
        font-size: 10px;
        color: $gray2;
        margin-bottom: 0px;
        text-align: right;
        line-height: 18px;
        font-weight: 400;
      }
    }

    .account__owner__image {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      position: relative;

      img {
        width: 100%;
        height: auto;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
