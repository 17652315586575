.push-alert-layout {
  background-color: $white;
}

.main-push-header {
}

.main-alert-table {
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 42px;

  .ant-table thead th {
    border: none;
    background-color: transparent;
  }

  .ant-table-tbody tr:nth-child(odd) {
    background: rgba(240, 242, 245, 0.35);
  }
  .ant-table th,
  .ant-table td {
    border: none;
  }
  .ant-table th:not(:last-child)::before {
    display: none;
  }
  .ant-table-wrapper .ant-table-column-sorter-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }

  .ant-table-column-sorter {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .ant-table-column-sorter i {
    margin: 0;
  }
}

.table-title {
  // font-family: "SF Pro Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}
.sent-type-immediate {
  // font-family: "SF Pro Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}
.sent-type-time {
  // font-family: "SF Pro Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #7f7f88;
}
.seen-by-div {
  display: flex;
  justify-content: center;
  .seen-by {
    display: flex;
    align-items: center;
    // font-family: "SF Pro Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    border: 1px dashed #a8a8a8;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
}

.leave-date {
  // font-family: "SF Pro Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
