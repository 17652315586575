.recent-search-title {
  padding-top: 31px;
  // padding-left: 42px;
}
.recent-search-title p {
  color: black;
  font-size: 32px;
  font-weight: 600;
}
.tabs-section-search {
  padding-top: 20px;
  // padding-left: 42px;
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #7b68ee;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-tabs .ant-tabs-tab {
    color: #7f7f88;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-tabs-ink-bar {
    // color: #7B68EE;
    border-bottom: 2px solid #7b68ee;
    border-radius: 4px 4px 0px 0px;
  }
}
.alert-section-row {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  .alert-section-inner-div {
    display: flex;
    justify-content: space-around;

    img {
      width: 43px;
      height: 43px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
.alert-section-content {
  padding-left: 16px;
}
.alert-section-content-title {
  color: black;
  font-size: 13px;
  font-weight: 500;
  height: 7px;
  span {
    font-weight: bolder;
  }
}
.alert-section-content-desc {
  color: #7f7f88;
  font-size: 12px;
  span {
    font-weight: bolder;
  }
}
