.main-group-card {
  position: relative;

  .groupCardDropdown {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .groupCard {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $grayborder;
    border-radius: 5px;
    padding: 22px 0;
    margin-bottom: 1.5rem;
    height: 190px;

    .roundIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 63px;
      height: 63px;
      background: linear-gradient(180deg, #7b68ee 0%, #b878ff 100%);
      border-radius: 100%;
      margin-bottom: 12px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .groups-icons {
      width: 48px;
      height: 48px;
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
      text-align: center;
    }

    .subTitle {
      color: $gray;
      font-size: 9px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
}

.group-detail-header {
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    padding-bottom: 10px;
  }

  p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
  }
}

.detail-body {
  padding-top: 35px;

  h4 {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
  }

  .ant-spin-nested-loading {
    height: 50vh;
    overflow: scroll;
  }
}
