.billingpagewrapper {
  .billingheader {
    padding-bottom: 35px;
    border-bottom: 0.5px solid #cfcfcf;

    .row {
      margin-bottom: 15px;

      p {
        a {
          color: #7b68ee;

          &:hover {
            color: #e806a0;
          }
        }
      }
    }

    .mt-26 {
      margin-top: 26px;
    }

    .sec-heading {
      padding-bottom: 16px;

      &.cloudHeading {
        padding-bottom: 0;
      }
    }
  }

  .planbilling-area {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 170px);
    padding-bottom: 100px;
    margin-top: 33px;

    .sec-heading {
      font-size: 24px;
    }
  }

  .planbilling {
    // padding: 43px 0 0 0;
    border: 1px solid #e9ebf0;
    margin: 0 0 29px 0;
    border-radius: 4px;

    .planA1 {
      text-align: center;
      padding: 43px 0 34px 0;
      min-height: 227px;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 0;
        padding-bottom: 9px;
      }

      .billingcontent {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
        padding-bottom: 25px;
        color: #000;
      }

      .billingprices {
        font-style: normal;
        font-weight: 510;
        font-size: 16px;
        line-height: 18px;
        color: #7f7f88;
        padding-bottom: 25px;
      }
    }

    .planB1 {
      // padding: 20px 20px 0;
      border-top: 1px solid #e9ebf0;
      // height: 162px;
      overflow-y: scroll;

      ul {
        margin: 22px;
        min-height: 44px;

        li {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          svg {
            margin: 0 10px 0 0;
          }
        }
      }
    }

    a.btn-currentplan {
      // padding: 9px 35px;
      border: 1px solid #ccc;
      color: #000;
      border-radius: 5px;
      font-style: normal;
      font-weight: 590;
      font-size: 12px;
      line-height: 37px;
      width: 120px;
      height: 37px;
      display: inline-block;
    }
  }

  a.btnplan {
    // padding: 9px 35px;
    // font-family: "SF Pro";
    font-style: normal;
    font-weight: 590;
    font-size: 12px;
    line-height: 37px;
    width: 120px;
    height: 37px;
    display: inline-block;

    &:hover {
      color: #fff;
    }
  }

  a.newbtn-currentplan {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 10px 23px;
    border: 1px solid #e9ebf0;
    border-radius: 5px;
    color: $black;
  }
}

.locationDefasset {
  margin-bottom: 13px;

  img {
    width: 64px;
    height: auto;
    margin: 0 auto;
  }
}

.progressheader {
  p {
    margin: 0 0 0 0;
  }

  .currentPlanPara {
    letter-spacing: 0px;
  }
}

.referralboxes {
  padding: 21px 29px;
  border: 1px solid rgba(162, 159, 177, 0.35);
  border-radius: 5px;

  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #000;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 18px;
  }
}

.billing-paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;

  b {
    font-weight: 600;
  }
}

.addBottomMargin {
  margin-bottom: 42px;
}
.addTopMargin {
  margin-top: 10px;
}
.billingPlanTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px 0px;
  flex-wrap: wrap;
}
.billingPlanTitleText {
  flex: 1;
  display: flex;
  align-items: center;
}
.customTable {
  width: 100%;
  font-weight: 500;
  td {
    padding-bottom: 10px;
  }
}

.tableRowUnderline {
  border-bottom: 0.5px solid #cfcfcf;
}
.tableRowTitle {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}
.childrenTableRow {
  td {
    font-weight: 400;
    padding: 18px 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;

    .marginLogo {
      font-weight: 500;
    }
  }
}
.marginLogo {
  margin-left: 10px;
}
.customManageBtn {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  padding: 9px 36px;
  outline: none;
  border: 1px solid #e9ebf0;
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
}
.manageText {
  font-weight: 600;
}

.customButton {
  display: flex;
  justify-content: flex-end;
}
