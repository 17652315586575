.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-dropdown {
  &.defaultDropdown {
    &.activity-drop-down {
      .ant-dropdown-menu {
        width: 155px;
        padding: 10px 2px;
        left: 3px;
      }

      .ant-dropdown-menu-title-content {
        font-weight: 400;
      }
    }

    &.newdrop {
      .ant-dropdown-menu {
        width: 150px;
      }
    }

    &.chatDropdown {
      .ant-dropdown-menu {
        width: 160px;
      }
    }

    .ant-dropdown-menu {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
      min-width: 120px;
      max-width: 160px;
      left: 3px;
      padding: 10px 0px;
    }

    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      font-weight: 600;
      text-transform: capitalize;
      font-size: 12px; //Nabeel

      .deleteLabel {
        color: $negativeRed;
        display: flex;
        align-items: center;
        width: 100%;

        svg {
          path {
            fill: $negativeRed;
          }
        }
      }

      svg {
        margin-right: 13px;
        margin-bottom: 1px;
      }
    }
  }

  &.balckedDropdown {
    .ant-dropdown-menu {
      width: fit-content;
    }

    .item {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .ant-dropdown-menu-item {
    padding: 7px 15px !important;
    color: rgba(0, 0, 0, 0.88);
    font-weight: normal;
    font-size: 2px;
    border-radius: 4px;
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
}

.ant-select {
  &.custom-select-box {
    margin-right: 10px;

    .ant-select-selector {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 146px;
      height: 40px;
      border-radius: 5px;
      background: $white;
      border: none !important;
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
      padding: 0 30px 0 11px;

      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
      }
    }

    .ant-select-selection-item {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: $gray2;
      display: flex;
      align-items: center;
    }
  }

  //status dropdown
  &.statusDropdown {
    margin-left: 0;

    .ant-select-selector {
      width: 130px;

      &:focus,
      &:active {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    .ant-select-selection-item {
      color: $black;
      font-weight: 600;
      padding: 0;
    }

    &.req-detail {
      margin-right: 0;
      border: 1px solid #e9ebf0;
      border-radius: 4px;
    }
  }
}

.ant-select-dropdown {
  &.status-select {
    .ant-select-item-option-content {
      // font-family: "SF Pro", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #000000;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      // font-family: "SF Pro", sans-serif;
      background-color: white;
      color: #000000 !important;
      border-radius: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      padding: 0px 6px;

      .ant-select-item-option-content {
        color: #000000;
        padding: 6px 6px;
      }

      .ant-select-item-option-state {
        padding: 6px 6px;
      }
    }

    .ant-select-item-option-content {
      padding: 0px 12px;
    }
  }

  &.main-select {
    padding-left: 3px;

    .ant-select-item-option-content {
      // font-family: "SF Pro", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #000000;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      // font-family: "SF Pro", sans-serif;
      background-color: $blueAccent;
      border-radius: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      padding: 0px 6px;

      .ant-select-item-option-content {
        color: $white;
        padding: 6px 6px;
      }

      .ant-select-item-option-state {
        padding: 6px 6px;
      }
    }

    .ant-select-item-option-content {
      padding: 0px 12px;
    }
  }

  &.request-select {
    padding-left: 3px;

    .ant-select-item-option-content {
      font-family: "SF Pro", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #000000;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      // font-family: "SF Pro", sans-serif;
      background-color: white;
      border-radius: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      padding: 0px 6px;

      .ant-select-item-option-content {
        color: black;
        padding: 6px 6px;
      }

      .ant-select-item-option-state {
        padding: 6px 6px;
      }
    }

    .ant-select-item-option-content {
      padding: 0px 12px;
    }
  }
}

.ant-select-dropdown.pageDropdown.css-dev-only-do-not-override-1vtf12y.ant-select-dropdown-placement-bottomLeft {
  margin-left: -12px;
  border-radius: 0 0 8px 8px;
  padding: 4px 0;
}

//Status dropdown
.ant-select-dropdown {
  &.pageDropdown {
    .ant-select-item-option-active {
      &.ant-select-item-option-selected {
        .ant-select-item-option-content {
          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .ant-select-item-option-active {
      .ant-select-item-option-content {
        &:hover {
          background-color: #f3f3f3;
        }
      }
    }
  }

  &.header-dropdown {
    .ant-select-item-option {
      padding: 0;
    }

    &.main-select {
      padding: 0 !important;

      .ant-select-item-option-selected {
        &:not(.ant-select-item-option-disabled) {
          padding: 6px;
        }
      }

      .ant-select-item-option-content {
        padding: 10px 12px;
      }
    }
  }

  &.statusDropdown {
    padding: 0;
    border-radius: 5px;

    .ant-select-item-option {
      // padding: 9px 7px;
      padding: 9px 8px 9px 12px;

      border-radius: 0;

      &:last-child {
        border-top: 1px solid #f0f0f0;
      }

      .ant-select-item-option-content {
        color: $black;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 0;
      }

      &.ant-select-item-option-active,
      &.ant-select-item-option-selected {
        background-color: $globalBg;
        // padding: 9px 7px;
        padding: 9px 8px 9px 12px;

        .ant-select-item-option-content {
          color: $black;
          font-weight: 600;
          padding: 0;
        }

        .ant-select-item-option-state {
          svg {
            path {
              fill: $black;
            }
          }
        }
      }
    }
  }
}

.statusBall {
  width: 10px;
  height: 10px;
  background-color: $blueAccent;
  margin-right: 8px;
  display: block;
  border-radius: 100%;

  &.Pending {
    background-color: $lightGray;
  }

  &.achieved {
    background-color: $green;
  }

  &.Approve {
    background-color: $green;
  }

  &.Decline {
    background-color: $negativeRed;
    border-top: 1px solid $lightGray2;
  }

  &.missed {
    background-color: $negativeRed;
    border-top: 1px solid $lightGray2;
  }
}

.custom-date-picker {
  padding: 0;
  width: 100%;
  border: none;
  box-shadow: none;

  input {
    // font-family: "SF Pro", sans-serif;
    color: #212529;
  }
}

//Without border Dropdown
.withoutDropdown {
  &.ant-select {
    padding: 0;
    border: 0;

    // width: 72px;
    .dropdownBox {
      .head {
        h6 {
          font-size: 13px;
          font-weight: 600;
          color: $black;
          margin: 0;
          color: #7b68ee;
        }

        .icon {
          display: none;
        }
      }

      .memb {
        h6 {
          font-size: 12px;
          font-weight: 400;
          color: $black;
          margin: 0;
        }

        .icon {
          display: none;
        }
      }

      p {
        display: none !important;
      }
    }

    .ant-select-selection-item {
    }
  }

  .ant-select-selector {
    border: 0 !important;
    background: transparent !important;
    padding: 0 !important;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      padding-inline-end: 23px;
    }
  }

  .ant-select-arrow {
    margin-left: 10px;
  }

  .ant-select-arrow {
    svg {
      width: 6px;
      height: 5px;

      path {
        fill: $black;
      }
    }
  }
}

.ant-select-dropdown {
  &.admindropdown {
    .ant-select-item-option {
      .ant-select-item-option-content {
        padding: 0;
      }
    }
  }

  &.customDropdownMenu {
    min-width: 190px !important;
    max-width: 190px !important;
    padding: 5px;
    width: 190px !important;

    .ant-select-item {
      background-color: transparent;

      &.ant-select-item-option-active {
        .ant-select-item-option-content {
          color: $black;
        }
      }

      &.ant-select-item-option-selected {
        .dropdownBox {
          background-color: $bg;

          .icon {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .dropdownBox {
        border-radius: 5px;
        padding: 10px 10px;
        white-space: normal;
        // text-overflow: unset;
        text-overflow: clip;
        overflow: unset;

        h6 {
          margin-bottom: 0.2rem;
        }

        .icon {
          display: none;
        }
      }

      padding: 0;
    }
  }

  .head {
    display: flex;
    justify-content: space-between;

    h6 {
      font-size: 14px;
      font-weight: 600;
      color: $black;
    }
  }

  p {
    font-size: 10px;
    margin: 0;
  }
}

//Invited Dropdown

.invitedDropdown {
  .ant-space-item {
    p {
      &.blue-accent {
        color: $blueAccent !important;
      }
    }

    svg {
      width: 6px;
      height: 5px;

      path {
        fill: $blueAccent;
      }
    }
  }
}

.ant-dropdown {
  &.invitedDropdown {
    .ant-dropdown-menu {
      padding: 0;

      .ant-dropdown-menu-item {
        padding: 12px 15px;
        border-bottom: 1px solid $bg;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

//Owner Dropdown Menu
.ownerMenu {
  .ant-space-item {
    p {
      color: $gray2 !important;
    }

    svg {
      width: 10px;
      height: 10px;

      path {
        fill: $gray2;
      }
    }
  }
}

.ant-dropdown {
  &.ownerDropdown {
    width: 190px;

    .ant-dropdown-menu {
      .ant-dropdown-menu-item-active {
        background-color: white;
      }
    }

    .adminRoleBox {
      h6 {
        line-height: 1;

        svg {
          width: 11px;
          height: 11px;
        }
      }
    }

    p {
      font-size: 10px;
      color: $gray;
    }
  }
}

#area {
  position: relative;
  top: 7px;
  /* Adjust the top position as per your requirements */
}

$select-dropdown-vertical-padding: 0;

ant-select-selection-selected-value {
  border-radius: 0px 8px 8px 0px;
  height: 53px;
}

.ant-select-selection.ant-select-selection--single {
  height: 53px;
}

.ant-select-selection.ant-select-selection--single
  > div
  > div
  > div
  > div
  + div {
  margin-top: -5px;
  padding: 4px 5px 5px 14px !important;
}

.ant-select-selection.ant-select-selection--single > div > div > div > div {
  margin-top: -20px;
}

.ant-select-selection.ant-select-selection--single[aria-expanded="true"]
  > div
  > div
  > div
  > div {
  margin-top: -10px;
}

/*style for when the menu is expanded: show shipment description above icon and name*/
.ant-select-selection.ant-select-selection--single[aria-expanded="true"]
  > div
  > div
  > div
  > div
  + div {
  margin-top: -15px;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  // border-color: $blueAccent;
  border-color: #000;
}

// Select with no floating label
.ant-select {
  &.noLabelSelect {
    width: 100%;

    &:hover {
      color: red !important;
    }

    .ant-select-selector {
      display: flex;
      align-items: center;
      // height: 50px;
      // height: 58px;
      height: 50px;
      padding: 0 0.75rem;
      border: none;
      border-radius: 0;

      .ant-select-selection-placeholder {
        font-weight: 400;
        font-size: 15px;
        color: $gray2;
        line-height: 1;
      }

      .ant-select-selection-item {
        font-size: 15px;
        color: $black;
        font-weight: 400;
        //display: flex;
        align-items: center;
        display: inline-flex;
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .ant-select-arrow {
      inset-inline-end: 21px;
    }

    &.dollarSelect {
    }
  }
}

.ant-select-dropdown .ant-select-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-select-dropdown::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
}

.ant-select-dropdown::-webkit-scrollbar-thumb {
  background-color: red !important;
  /* Set the color of the scrollbar thumb */
}

.ant-select-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Set the color of the scrollbar thumb on hover */
}

.ant-select-dropdown {
  .rc-virtual-list-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3) !important;
  }
}

.ant-dropdown {
  &.singleDropdown {
    min-width: 206px !important;
    // height: 40px;
    width: auto;

    .ant-dropdown-menu {
      padding: 0;
      height: 40px;

      li {
        height: 40px;
      }

      .ant-dropdown-menu-item-active {
        background-color: white;
      }
    }

    .adminRoleBox {
      &.lockBox {
        h6 {
          font-size: 11px;
          color: #717171;
        }
      }

      h6 {
        margin-bottom: 0;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        span {
          height: 16px;
          margin-right: 8px;
        }

        // svg {
        //   width: 10px;
        //   height: 10px;
        // }
      }
    }

    p {
      font-size: 10px;
      color: $gray;
    }
  }
}

.singLock {
  &.imageMargin {
    .ant-space-item {
      margin-right: -3px;
    }
  }

  &.resourceLock {
    position: absolute;
    top: 12px;
  }

  .ant-space-item {
    margin-bottom: 2px;
    margin-right: 6px;

    p {
      color: $gray2 !important;
    }

    // svg {
    //   // width: 10px;
    //   // height: 10px;
    //   path {
    //     fill: #7B68EE  !important;
    //   }
    //   &:hover{
    //     path {
    //       fill: #7B68EE !important;
    //     }
    //   }
    // }
  }

  &.ant-dropdown-trigger {
    // svg {
    //   // width: 10px;
    //   // height: 10px;
    //   path {
    //     fill: #7B68EE !important;
    //   }
    //   &:hover{
    //     path {
    //       fill: #7B68EE !important;
    //     }
    //   }
    // }
  }

  &.ant-dropdown-open {
    // svg {
    //   // width: 10px;
    //   // height: 10px;
    //   fill: #7B68EE;
    //   path {
    //     fill: #7B68EE !important;
    //   }
    //   &:hover{
    //     path {
    //       fill: #7B68EE !important;
    //     }
    //   }
    // }
  }
}

.drivetable {
  &.for-fixed-state {
  }
}

.goal-page-black-placeholder {
  .ant-select-selection-placeholder {
    color: black !important;
    font-weight: 600 !important;
  }
}

.statusDropdown1 {
  border: 1px solid #ebeced;
  border-radius: 4px;

  .ant-select-item-option {
    transition: none;
  }

  // .ant-select-item-option-active {
  //   background-image: url('data:image/svg+xml,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M4.12509 9.50009C4.02909 9.50009 3.93309 9.46334 3.85997 9.39022L0.484969 6.01522C0.338344 5.86859 0.338344 5.63159 0.484969 5.48497L1.60997 4.35997C1.75659 4.21334 1.99359 4.21334 2.14022 4.35997L4.12509 6.34484L9.85997 0.609969C10.0066 0.463344 10.2436 0.463344 10.3902 0.609969L11.5152 1.73497C11.6618 1.88159 11.6618 2.11859 11.5152 2.26522L4.39022 9.39022C4.31709 9.46334 4.22109 9.50009 4.12509 9.50009Z" fill="black" /></svg>') !important;
  //   background-position: 92% 13px !important;
  //   background-repeat: no-repeat !important;
  // }

  .ant-select-selection-item {
    color: $black !important;
    font-weight: 600 !important;

    &::before {
      width: 10px;
      height: 10px;
      content: "";
      border-radius: 50%;
      margin-right: 8px;
      color: $blueAccent;
    }
  }

  &.Active {
    .ant-select-selection-item {
      &::before {
        background: $blueAccent;
      }
    }
  }

  &.Achieved {
    .ant-select-selection-item {
      &::before {
        background: $green;
      }
    }
  }

  &.Missed {
    .ant-select-selection-item {
      &::before {
        background: $negativeRed;
      }
    }
  }
}

.ant-dropdown-trigger {
  &.inviteTitle {
    display: block;

    &:hover {
      svg {
        fill: #7b68ee !important;

        path {
          fill: #7b68ee !important;
        }
      }
    }
  }
}

.invitedDropdown {
  .ant-dropdown-menu .ant-dropdown-menu-item {
    margin: 0 !important;
  }

  .inviteItem {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 7px;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &.cancelInvite {
      color: #000;
    }
  }
}

.custom-width-051 {
  width: 150px !important;
}
