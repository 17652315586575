$purpleAccent: #9b51e0;
$pink: #ff519a;
$blueAccent: #7b68ee;
$aquaAccent: #50d8cd;
$white: #fff;
$lightGray: #cfcfcf;
$gray: #717171;
$paleGray: #e9ebf0;
$black: #000000;
$bg: #f1f3f6;
$darkPink: #df3c81;
$darkPurple: #6a58d7;
$gray2: #a8a8a8;
$green: #00b000;
$negativeRed: #ff0054;
$globalBg: #f5f6f8;
$lightPurple: #aa8df1;
$paleWhite: #f0f2f5;
$grayborder: #ebeced;
$lightGray2: #d9d9d9;
$gray3: #565656;
$gray4: #e7e7e7;
$btnBgGradiant: linear-gradient(270deg, #e806a0 0%, #7b68ee 100%);
$primary-hover: #6b5ad7;
$btnBgGradiant: linear-gradient(270deg, #e806a0 0%, #7b68ee 100%);

$btnBgGradiantHover: linear-gradient(160deg, #e806a0 0%, #7b68ee 100%);

$secondarygrey: #fafbfc;

$deleteRed: #e4004b;
$border: #d7d7d7;

:root {
  --bs-link-color: #7b68ee;
  --bs-link-color-rgb: rgb(123, 104, 238);
}
