/*** Generic Modal Sizes ***/
.modal-lg {
  width: 765px;
}

.modal-body {
  padding: 0 38px;

  // &.alertform{
  //   height: calc(100% - 64px);
  // }
}

/*** Generic Modal Styling ***/

.generic-modal {
  &.userProfileModal {
    .modal-header {
      .modal-title {
        padding-left: 30px;
      }
    }
  }

  .modal-header {
    padding: 40px 38px 39px;
    border: 0;
    background: #f5f6f8;

    .modal-title {
      font-size: 26px;
      font-weight: 600;
      color: $black;
      line-height: 1;
      // padding-left: 40px;
    }

    .closeBtn {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s ease;

      &:hover {
        background: $bg;
        cursor: pointer;
      }
    }
  }

  .modal-body {
    padding: 0 38px;
    min-height: 437px;
    // overflow: hidden;
  }
  .customResourceModalBody {
    min-height: 420px !important;
  }
  .customNewTaskModalBody {
    min-height: 420px !important;
  }
  .customNewSurveyModalBody {
    min-height: 420px !important;
  }
  .customNewQuizBody {
    min-height: 420px !important;
  }
  .customNewDocBody {
    min-height: 420px !important;
  }
  .customOnboardingModalBody {
    min-height: 420px !important;
  }

  .modal-footer {
    // padding: 25px 38px;
    padding: 25px 41px;

    box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);

    .custom-footer-btns {
      display: flex;
      align-items: center;

      .margin-right-26 {
        margin-right: 26px;
      }

      .custom-width-332 {
        width: 332px !important;
      }
    }
  }
}

.customGoalModalHeader {
  padding: 26px 38px !important;
  margin-bottom: 14px;
}
.customEventModalHeader {
  padding: 26px 38px !important;
  margin-bottom: 24px;
}
.customEventModal {
  .modal-body {
    min-height: 420px !important;
  }
}
.customPushAlertModalHeader {
  border-bottom: none;
  padding-right: 38px !important;
  padding-top: 26px !important;
  background: #f5f6f8;
  margin-bottom: 24px !important;
}
.customPushAlertModalTitle {
  font-size: 26px !important;
  padding-bottom: 10px !important;
  padding-left: 40px !important;
}
.customNewResourceModalHeader {
  padding: 26px 38px !important;
  margin-bottom: 16px;
  background: rgb(245, 246, 248);
}
.customNewTaskModalHeader {
  padding: 26px 38px !important;
  margin-bottom: 24px;
  background: rgb(245, 246, 248);
}
.customNewSurveyHeader {
  padding-bottom: 26px !important;
  margin-bottom: 20px;
}
.customNewQuizHeader {
  padding: 26px 38px !important;
  margin-bottom: 22px;
}
.customFlashcardSetModal {
  padding: 26px 38px !important;
  margin-bottom: 20px;
}
.customUploadDocument {
  padding: 26px 38px !important;
  margin-bottom: 20px;
}
.customOnboardingModalHeader {
  padding: 26px 38px !important;
  margin-bottom: 20px;
}
.ant-tabs-tab {
  padding: 6px 0 !important;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-tabs
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: 0 0 0 24px;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  text-shadow: unset;
  color: unset;
}

.main-push-alert {
  .main-alert-box {
    max-width: 213px;
    border: 1px solid rgba(162, 159, 177, 0.35);
    border-radius: 4px;
    text-align: center;
    padding: 16px 37px 23px;
    text-align: center;
    height: 160px;
    transition: 0.5s ease;

    &.selected {
      border-color: #7b68ee !important;

      h3 {
        font-weight: 600;
      }

      p {
        color: #a8a8a8;
      }

      svg {
        path {
          fill: $blueAccent;
        }

        rect {
          fill: $blueAccent;
        }
      }
    }

    .icon-div {
      border-radius: 50%;
      background: $bg;
      width: 48px;
      height: 48px;
      vertical-align: middle;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      margin-bottom: 0;
      padding-top: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: $black;
    }

    p {
      // line-height: 17px;
      color: #a8a8a8;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0;
      padding-top: 8px;
      white-space: normal;
      line-height: 17px;
    }

    svg {
      path {
        transition: 0.5s ease;
      }
    }

    &:hover {
      border-color: $blueAccent;
      cursor: pointer;

      svg {
        path {
          fill: $blueAccent;
        }

        rect {
          fill: $blueAccent;
        }
      }
    }
  }

  .ant-tabs-nav {
    &::before {
      border-bottom: none;
    }
  }

  .ant-tabs-ink-bar-animated {
    display: none;
  }

  .ant-tabs-tab-active {
    border-bottom: none;

    .main-alert-box {
      border: 1px solid $blueAccent;

      .icon-div {
        svg {
          path {
            fill: $blueAccent;
          }

          rect {
            fill: $blueAccent;
          }
        }
      }
    }
  }
}

.zero-step {
  &.main-push-alert {
  }

  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;

  .main-alert-box {
    max-width: 213px;
    border: 1px solid rgba(162, 159, 177, 0.35);
    border-radius: 4px;
    text-align: center;
    padding: 17px 37px 23px;
    text-align: center;
    height: 168px;
    transition: 0.5s ease;

    .icon-div {
      border-radius: 50%;
      background: $bg;
      width: 48px;
      height: 48px;
      vertical-align: middle;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      margin-bottom: 0;
      padding-top: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: $black;
    }

    p {
      color: $gray2;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      padding-top: 8px;
      margin-bottom: 0;
      white-space: normal;
    }

    svg {
      path {
        transition: 0.5s ease;
      }
    }

    &:hover {
      border-color: $blueAccent;
      cursor: pointer;

      svg {
        path {
          fill: $blueAccent;
        }

        rect {
          fill: $blueAccent;
        }
      }
    }
  }

  .main-alert-box-new {
    width: 332px;
    border: 1px solid rgba(162, 159, 177, 0.35);
    border-radius: 4px;
    text-align: center;
    padding: 16px 30px 15px 23px;
    text-align: center;
    height: 90px;
    transition: 0.5s ease;

    .icon-div {
      border-radius: 50%;
      background: $bg;
      width: 48px;
      height: 48px;
      vertical-align: middle;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      margin-bottom: 0;
      padding-top: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: $black;
    }

    p {
      color: $gray2;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      padding-top: 8px;
      margin-bottom: 0;
      white-space: normal;
    }

    svg {
      path {
        transition: 0.5s ease;
      }
    }

    &:hover {
      border-color: $blueAccent;
      cursor: pointer;

      svg {
        path {
          fill: $blueAccent;
        }

        rect {
          fill: $blueAccent;
        }
      }
    }
  }

  .ant-tabs-nav {
    &::before {
      border-bottom: none;
    }
  }

  .ant-tabs-ink-bar-animated {
    display: none;
  }

  .ant-tabs-tab-active {
    border-bottom: none;

    .main-alert-box {
      border: 1px solid $blueAccent;

      .icon-div {
        svg {
          path {
            fill: $blueAccent;
          }

          rect {
            fill: $blueAccent;
          }
        }
      }
    }

    .main-alert-box-new {
      border: 1px solid $blueAccent;

      .icon-div {
        svg {
          path {
            fill: $blueAccent;
          }

          rect {
            fill: $blueAccent;
          }
        }
      }
    }
  }

  .min_box {
    border: 1px solid rgba(162, 159, 177, 0.35);
    border-radius: 4px;

    .header_box {
      background: #f1f3f6;
      border-bottom: 1px solid rgba(162, 159, 177, 0.35);
      border-radius: 4px 4px 0px 0px;
      height: 44px;
      line-height: 44px;
      font-style: normal;
      font-weight: 500;
      color: #000000;
      padding-left: 25px;
      border-radius: 4px, 4px, 0px, 0px;
    }

    .all_select {
      padding: 23px 25px;
      height: 260px;
      overflow: auto;

      .select_box_new {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 18px;

        .select_label_new {
          width: 50px;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 36px;
          color: #000000;
        }

        .select_option_new {
          width: 100%;
          box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);

          .ant-select {
            .ant-select-selector {
              min-height: 58px;
              height: auto;
              border-radius: 5px;
              border: 1px solid rgba(162, 159, 177, 0.35);
              background: #fff;
              padding: 8px;

              .ant-select-selection-overflow-item {
                .ant-select-selection-item {
                  height: 44px;
                  padding: 12px;
                  color: #000;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;
                  border-radius: 4px;
                  border: 1px solid rgba(162, 159, 177, 0.35);
                  background: #f1f3f6;

                  .ant-select-selection-item-remove {
                    svg {
                      width: 14px;
                      height: auto;
                      margin-left: 5px;
                      margin-top: 3px;

                      path {
                        fill: #000;
                      }
                    }
                  }
                }
              }
            }

            &:hover {
              .ant-select-selector {
                border: 1px solid #7b68ee;
              }
            }
          }
        }
      }
    }
  }
  .min_box.new_segment {
    .all_select {
      height: auto;
    }
  }
}

.create-alert-div {
  form {
    display: flex;
    gap: 24px;
    justify-content: space-evenly;

    align-items: center;

    .customLabelGroup {
      // margin-right: auto;
      &:first-child {
        margin-right: auto;
      }

      &:nth-child(2) {
        // margin: 0 auto 20px auto;
        margin-right: auto;
        margin-left: 0;
      }

      &.form-floating {
        .form-control {
          width: 207px;
          height: 58px;
        }
      }
    }
  }
}

.main-create-push-modal {
  .modal-content {
    width: 765px;
    height: 627px;

    .modal-body {
      .ant-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .modal-header {
      border-bottom: none;
      padding-top: 40px;
      padding-right: 38px;

      .closeBtn {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s ease;
        margin-bottom: 20px;

        &:hover {
          background: $bg;
          cursor: pointer;
        }
      }

      .modal-title {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
      }

      svg {
        cursor: pointer;
      }

      .btn-close {
        background-image: url("../../images/modal-cross-icon.png");
      }
    }

    .modal-footer {
      padding: 0 20px;
      height: 100px;
      box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
    }
  }
}

/* leave page modal */
.main-leave-page-modal {
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.15));

  .modal-content {
    height: auto;
    width: 480px;
    margin: 0 auto;
    padding: 31px 68px 54px 68px;

    .modal-body {
      text-align: center;
      padding: 0;

      .leaveModalIcon {
        margin-bottom: 25px;
      }

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        color: $black;
        margin-bottom: 12px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 1.5;
        margin: 0;
      }

      .btn-div {
        margin-top: 27px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.tabs-modal {
  // overflow: hidden;

  &.noBorders {
    .ant-tabs-nav {
      padding: 0;

      &:before,
      &::after {
        content: normal;
      }
    }
  }

  .ant-tabs-nav {
    .ant-tabs-ink-bar {
      background: $blueAccent;
      border-radius: 4px 4px 0px 0px;
      height: 4px;
    }

    .ant-tabs-tab {
      font-weight: 600;
      font-size: 15px;

      &:hover {
        color: $blueAccent;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: $blueAccent;
        }
      }
    }

    &:before {
      // left: -50%;
      border-bottom: 1px solid #cfcfcf;
      content: "";
      // width: 100vw;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      // left: -50%;
      border-top: 1px solid #cfcfcf;
      // width: 100vw;
    }
  }
}

.main-approve-modal {
  .modal-content {
    width: 560px;
    // height: 450px;
    margin: 0 auto;

    .modal-header {
      align-items: flex-end;
      padding: 0;
      border: none;

      svg {
        cursor: pointer;
      }
    }

    .modal-body {
      text-align: center;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        color: $black;
        margin-bottom: 0;
        padding-top: 35px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 0;
        padding-top: 14px;
        padding-bottom: 25px;
      }

      .btn-div {
        padding-top: 27px;
        padding-bottom: 43px;
        display: flex;
        justify-content: space-evenly;
      }
    }

    span {
      font-weight: bold;
    }
  }

  .form-control {
    // font-family: "SF Pro Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    height: 200px;
    resize: none;
    margin: 0 auto;
    width: 465px;

    &::placeholder {
      color: #a8a8a8;
      // font-family: "SF Pro Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }
  }
}

.proTipText {
  font-size: 12px;

  span {
    font-weight: 600;
  }
}

.ant-upload-wrapper .ant-upload-drag {
  background: transparent;
  border: 0;
}

// *************************************
//           Share modal CSS
// *************************************

.shareModal {
  &.mainShareMemberBody {
    .modal-body {
      padding: 24px 62px 100px 30px;
    }
  }

  .modal-dialog {
    width: 655px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    padding: 40px 38px 32px 38px;

    .closeBtn {
      margin-top: -15px;
    }

    .modal-title {
      margin-top: 9px;

      p {
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 13px;
      }
    }
  }

  .modal-body {
    padding: 25px 62px 25px 32px;
    min-height: auto;
    border-top: 1px solid #e9ebf0;
    border-bottom: 1px solid #e9ebf0;
  }

  .modal-footer {
    box-shadow: none;
    padding: 37px 30px 52px 30px;
    border: 0;
    justify-content: flex-start;
  }
}

.shareContainer {
  h6 {
    margin-bottom: 18px;
    font-size: 16px;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    color: $gray2;
    margin-bottom: 18px;
    word-break: break-word;
  }
}

// *************************************
//           Delete modal CSS
// *************************************

.delete-modal {
  .modal-content {
    min-width: 580px;
  }

  .modal-body {
    text-align: center;
    padding: 10px 22px 67px;
  }

  .leaveModalIcon {
    margin-top: 18px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 30px;
    margin-bottom: 12px;
  }

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 34px;
    padding-left: 52px;
    padding-right: 52px;
  }

  button {
    width: 200px;
    // margin-right: 13px;
  }
}

// *************************************
//           Delete doc modal CSS
// *************************************
.delete-doc-modal {
  .modal-content {
    min-width: 480px;
    /* height: 320px; */
    position: relative;
    padding: 70px 50px 47px 50px;
  }

  .modal-body {
    text-align: center;
    padding: 0;
  }

  .leaveModalIcon {
    /* margin-bottom: 36px; */
    position: absolute;
    top: -95px;
    left: 0;
    right: 0;
  }

  h3 {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 0;
  }

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 54px;
  }

  .btn-div {
    display: flex;
    justify-content: center;
    gap: 17px;
  }

  button {
    width: 200px;
    margin-right: 13px;
  }
}

// *************************************
//           logout modal CSS
// *************************************
.logout-modal {
  .modal-content {
    min-width: 480px;
    /* height: 320px; */
    position: relative;
    padding: 70px 50px 47px 50px;
  }

  .modal-body {
    text-align: center;
    padding: 0;
  }

  .leaveModalIcon {
    /* margin-bottom: 36px; */
    position: absolute;
    top: -95px;
    left: 0;
    right: 0;
  }

  h3 {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 0;
  }

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 54px;
  }

  .btn-div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  button {
    width: 200px;
    margin-right: 13px;
  }
}

// *************************************
//           Status modal CSS
// *************************************

.statusModal {
  .modal-header {
    .modal-title {
      p {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }

    .closeBtn {
      margin-top: -15%;
      margin-right: -4%;
    }
  }

  .modal-dialog {
    width: 600px;
  }

  .modal-body {
    min-height: auto;
    margin-top: 26px;

    textarea {
      height: 200px;
    }
  }

  .modal-footer {
    box-shadow: none;
    padding: 27px 30px 32px;
    border: 0;
    justify-content: flex-start;
  }
}

.height-dragger {
  &.ant-upload-wrapper {
    .ant-upload-drag {
      height: auto;
    }
  }
}

.ant-upload-list-item-container {
  .ant-upload-list-item {
    &.ant-upload-list-item-error {
      color: $gray !important;

      .anticon {
        color: $gray !important;
      }

      .ant-upload-list-item-name {
        color: $gray;
      }
    }
  }

  .ant-tooltip {
    display: none !important;
  }
}

.main-badges {
  .badge-div {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 20px;
    grid-gap: 20px;

    .single-badge {
      border: 1px solid rgba(162, 159, 177, 0.35);
      border-radius: 5px;
      width: 100%;
      height: 60px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .selected {
      border: 1px solid #7b68ee;
    }
  }
}

.invite-staff {
  .closeBtn {
    position: absolute;
    right: 37px;
    top: 52px;
    z-index: 555;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease;

    &:hover {
      background: $bg;
      cursor: pointer;
    }
  }

  .modal-content {
    // background-image: url("../../images/banner.png");
    // background-repeat: no-repeat;
    // background-position: right;
    // background-size: contain;
  }

  .modal-header {
    .closeBtn {
      position: absolute;
      right: 28px;
      top: 29px;
    }
  }

  .modal-body {
    button {
      margin-bottom: 20px;
    }

    .learnmore {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 30px;
      color: $blueAccent;
      text-align: center;
    }
  }

  .sub-invite {
    h3 {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      padding-bottom: 10px;
      text-align: center;
      padding-top: 45px;
    }

    p {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      padding-bottom: 28px;
      text-align: center;
    }
  }
}

.share-member-body {
  .modal-body {
    padding: 24px 62px 100px 30px;
  }

  .member-info {
    display: flex;
    align-items: center;
    gap: 26px;

    .avatar-img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .info {
      h5 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 36px;
      }

      p {
        margin-bottom: 0;
        color: #a8a8a8;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
}

//Delete Group Modal
.main-delete-group {
  .modal-content {
    padding: 47px 97px 55px 97px;
    max-width: 665px;
    margin: 0 auto;

    .modal-header {
      padding: 0;
      border: 0;

      .closeBtn {
        position: absolute;
        right: 28px;
        top: 29px;
        cursor: pointer;
      }
    }

    .modal-body {
      text-align: center;
      padding: 0;

      h4 {
        font-size: 30px;
        line-height: 36px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0;
        padding-bottom: 22px;
        padding-top: 38px;

        span {
          color: #ff0054;
        }
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 0;
        line-height: 28px;
        padding-bottom: 47px;
      }
    }

    .btn-div {
      display: flex;
      justify-content: center;
      gap: 13px;
    }
  }
}

//share point
.main-point-group {
  .modal-content {
    padding: 45px 39px 55px 39px;
    max-width: 665px;
    margin: 0 auto;

    .modal-header {
      padding: 0;
      border: 0;

      .closeBtn {
        position: absolute;
        right: 28px;
        top: 50px;
        cursor: pointer;
      }
    }

    .modal-body {
      padding: 0;

      h4 {
        font-size: 30px;
        line-height: 36px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0;
        padding-bottom: 9px;
        /* padding-top: 38px; */
        text-align: left;
      }

      .text-point {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 0;
        line-height: 28px;
        padding-bottom: 39px;

        text-align: left;
      }
    }

    .btn-div {
      display: flex;
      justify-content: center;
      gap: 13px;
    }
  }
}

.main-point-div {
  position: relative;

  .increment-div {
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 16px;
    background: white;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
    }

    .inc-btns {
      display: flex;
      flex-direction: column;
      background: #f1f3f6;
      border-radius: 4px;
      padding: 5px 8px;
      position: relative;

      .disabled {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 50;
        }

        svg {
          path {
            fill: $gray2;
          }
        }
      }

      svg {
        cursor: pointer;
      }

      &:first-child {
        margin-bottom: 5px;
      }
    }
  }

  input {
    font-weight: 600;
    font-size: 18px;
  }
}

.notificationLinkBox {
  &.newHire {
    margin-top: 0.5rem !important;

    .header.d-flex {
      .me-3 {
        margin-top: -3px;
      }
    }
  }

  .header.d-flex {
    .me-3 {
      margin-top: -3px;
    }
  }

  p {
    font-size: 12px;
    margin-top: 2px;
  }
}
.notificationLinkBox.noMarginTop {
  p {
    margin-top: 0 !important;
  }
}

.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.ant-notification-notice {
  padding: 15px 24px !important;

  .ant-notification-notice-close {
    top: 17px !important;
  }

  .ant-notification-notice-close:hover {
    color: rgba(0, 0, 0, 0.88);
    background-color: transparent !important;
  }
}

.deletedNotification {
  max-width: 370px !important;
  height: auto !important;
  width: 370px !important;
  border-radius: 100px !important;
  border-left: 6px solid #ff0054 !important;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.3) !important;
}

.updatedNotification {
  max-width: 370px;
  height: 54px;
  width: 370px;
  border-radius: 100px;
  border-left: 6px solid #7b68ee;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.3);
}

.createdNotification {
  max-width: 370px !important;
  height: auto !important;
  width: 370px !important;
  border-radius: 100px !important;
  border-left: 6px solid #00b000 !important;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.3) !important;
}

.modal-content {
  .modal-body {
    .form-control {
      font-size: 15px;
    }
  }
}

//USER LIMIT MODAL
.mainUserLimit {
  .subMainUserList {
    .modal-content {
      max-width: 600px;
      // height: 428px;
      margin: 0 auto;

      .modal-body {
        padding: 0;

        .headerCrossIcon {
          text-align: end;
          padding-right: 14px;
          padding-top: 13px;
        }

        .subModalContent {
          // padding: 68px 35px;
          padding: 0px 35px 54px;
          text-align: center;

          h3 {
            margin-bottom: 0;
            padding-top: 30px;
            font-size: 30px;
            font-weight: 590;
          }

          p {
            max-width: 529px;
            width: 100%;
            text-align: center;
            margin-bottom: 0;
            font-size: 15px;
            padding-top: 18px;
            padding-bottom: 20px;
            font-weight: 400;
            line-height: 28px;
          }

          .buttonDiv {
            display: flex;
            justify-content: center;
            gap: 13px;
            align-items: center;

            .purpleBg {
              background-color: $blueAccent;
              color: white;
              border: 1px solid $blueAccent;

              &:hover {
                background-color: $blueAccent;
                border: 1px solid $blueAccent;
                color: white;
                box-shadow: none;
              }
            }

            .greyTransBg {
              color: #7f7f88;
              border: 1px solid #7f7f88;

              &:hover {
                color: #7f7f88;
                border: 1px solid #7f7f88;
              }
            }

            button {
              line-height: normal;
              width: 200px;
              height: 50px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

.group-modal-body {
  min-height: 300px !important;
}

.main-push-alert-new {
  .ant-tabs-tab {
    margin: 0 0 0 0;

    &:nth-child(2) {
      margin: 0 0 0 27px;
    }
  }

  .ant-tabs-nav-wrap {
    overflow: visible !important;

    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  .ant-tabs-nav-list {
    transform: translate(0, 0) !important;
  }
}

.alertform.tabs-modal.noBorders.zero-form {
  min-height: 437px;
}

input.disabled-field.goal-page-black-placeholder.form-control {
  cursor: not-allowed;
}
.new-flashcard-set .tabs-modal {
  min-height: 360px;
}
.customFlashcardSetBody {
  min-height: 420px !important;
}

.rewardModal .modal-body {
  padding-top: 20px;
}
