.btn-primary {
  position: relative;
  min-width: 85px;
  background-color: $blueAccent;
  border-color: $blueAccent;
  min-height: 55px;
  border-radius: 0.375rem;
  font-size: 15px;
  transition: 0.5s ease;
  font-weight: 500;
  @media (max-width: 768px) {
    min-height: 45px;
  }
  &.custom-width-690 {
    width: 690px;
  }
  &:disabled {
    background-color: $blueAccent;
    opacity: 0.5;
  }
  svg {
    path {
      transition: 0.5s ease;
    }
  }

  &.transparent-btn {
    width: 180px;
    height: 34px;
    min-height: 34px;
    background: $lightPurple;
    border-color: $lightPurple;
    border-radius: 3px;
    z-index: 55;
    position: relative;

    &:focus,
    &:active,
    &:hover {
      background-color: $white;
      border-color: $blueAccent;
      color: $blueAccent;

      svg {
        path {
          fill: $blueAccent;
        }
      }
    }
  }

  &.btn-gray {
    background: $bg;
    border-color: $bg;
    color: $gray;

    &:focus,
    &:active,
    &:hover {
      background-color: #dee2e6 !important;
      border-color: #dee2e6 !important;
      color: #212529 !important;
    }
  }

  &.no-bg {
    background: transparent;
    border-color: transparent;
    color: $gray;
    &:hover {
      color: $white;
    }
  }

  &:focus,
  &:active {
    background-color: $blueAccent;
    border-color: $blueAccent;
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba($blueAccent, 0.5);
  }

  &:disabled {
    background-color: $blueAccent;
    border-color: #bdb4f7;
    opacity: 1;

    &:after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: 101%;
      height: 104%;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 5px;
    }
  }

  &:first-child {
    &:hover,
    &:active,
    &:focus {
      background-color: $primary-hover;
      border-color: $blueAccent;
      color: $white;
      cursor: pointer;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $primary-hover;
    border-color: $blueAccent;
    color: $white;
    cursor: pointer;
  }
}

.profile-hover {
  .btn-primary:disabled {
    opacity: 0.3 !important;
  }
}

.invite-btn {
  svg {
    margin-right: 5px;
  }
  &.btn.active {
    color: #ffffff !important;
    background: #aa8df1 !important;
  }
  &.btn-primary {
    background: #aa8df1;
    border-radius: 3px;
    width: 180px;
    min-height: 34px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
    border: none;

    &:active,
    &:focus,
    &:hover {
      color: #aa8df1 !important;
      background: #ffffff !important;
      cursor: pointer;
      svg {
        path {
          fill: #aa8df1;
        }
      }
    }
  }
}

.btn-gradient {
  background: $btnBgGradiant;
  width: 140px;
  height: 40px;
  color: $white;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  border-radius: 3px;
  border: none;
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 8px;
  }

  &:hover {
    background: $btnBgGradiantHover;
  }
}

.btn-gradient--hover {
  &:hover {
    background: $btnBgGradiantHover !important;
    color: #fff !important;
  }
}

.btnplan__disabled {
  color: #7f7f88 !important;
  background: #fff !important;
  border: 1px solid #7f7f88 !important;
  margin: 0 auto;
  cursor: default !important;
  font-size: 12px;
  width: 120px;
  height: 37px;
  font-weight: 600;

  span {
    margin-right: 0 !important;
  }
}

.modal-color-btn {
  width: 690px;
  height: 50px;

  &:hover {
    background-color: $white;
    border-color: $blueAccent;
    color: $blueAccent;
  }

  &:disabled {
    background-color: $blueAccent;
    border-color: #bdb4f7;

    // opacity: 0.2;
    border-radius: 4px;
  }
}

.border-grey-sm-btn {
  width: 175px;
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #a8a8a8;
  border: 1px solid rgba(162, 159, 177, 0.55);
  border-radius: 4px;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $blueAccent !important;
    color: $blueAccent !important;
    background-color: transparent !important;
  }
}

.solid-grey-sm-btn {
  width: 175px;
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #a8a8a8;
  border: 1px solid #e9ebf0;
  border-radius: 4px;
  background-color: #e9ebf0;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #dee2e6 !important;
    color: #7f7f88 !important;
    background-color: #dee2e6 !important;
  }
}

.solid-grey-sm-btn-leave {
  width: 175px;
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #a8a8a8;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #d9d9d9 !important;
    color: #7f7f88 !important;
    background-color: #d9d9d9 !important;
  }
  &.me-2 {
    margin-right: 0.75rem !important;
  }
}

.solid-red-sm-btn {
  width: 175px;
  height: 50px;
  background-color: $negativeRed;
  border-radius: 4px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border: 1px solid $negativeRed;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #e4004b !important;
    background-color: #e4004b !important;
    color: #ffffff !important;
  }
}
.solid-red-lg-btn {
  width: 200px;
  height: 50px;
  background-color: $negativeRed;
  border-radius: 4px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border: 1px solid $negativeRed;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $negativeRed !important;
    background-color: transparent !important;
    color: $negativeRed !important;
  }
}

.solid-pink-sm-btn {
  width: 180px;
  height: 50px;
  background-color: #ff519a;
  border-radius: 4px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  border: 1px solid $negativeRed;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $negativeRed !important;
    background-color: transparent !important;
    color: $negativeRed !important;
  }
}

.border-grey-log-btn {
  width: 180px;
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #a8a8a8;
  border: 1px solid rgba(162, 159, 177, 0.55);
  border-radius: 4px;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $blueAccent !important;
    color: $blueAccent !important;
    background-color: transparent !important;
  }
}

.solid-cancel-grey-btn {
  // font-family: "SF Pro Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #a8a8a8;
  background: #f1f3f6;
  border-radius: 4px;
  width: 225px;
  height: 52px;
  border: none;

  &:hover {
    color: #a8a8a8;
    background: #f1f3f6;
    border-radius: 4px;
    width: 225px;
    height: 52px;
    border: none;
  }
}

.solid-learn-grey-btn {
  &.new {
    background-color: transparent;
    border: 1px solid rgba(162, 159, 177, 0.55);
    border-radius: 4px;
  }
  // font-family: "SF Pro Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #a8a8a8;
  background: #f1f3f6;
  border-radius: 4px;
  width: 200px;
  height: 50px;
  border: none;
  transition: 0.5s ease;
  &:hover {
    border: 1px solid #f1f3f6;
    color: #a8a8a8;
  }
}

.solid-gotit-grey-btn {
  // font-family: "SF Pro Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  background: #7b68ee;
  border-radius: 4px;
  width: 200px;
  height: 50px;
  border: none;
  transition: 0.5s ease;
  &.give-points {
    width: 178px;
  }
  &:hover {
    border: 1px solid #7b68ee;
  }
}

.solid-approve-green-btn {
  background: #00b000;
  border-color: #00b000;
  border-radius: 4px;
  color: #ffffff;
  // font-family: "SF Pro Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  width: 225px;
  height: 52px;
  &:hover {
    background-color: #009e00;
    border-radius: 4px;
    border-color: #009e00 !important;
  }
  &:active {
    background-color: #009e00 !important;
    border-radius: 4px !important;
    border-color: #009e00 !important;
  }
}

.solid-decline-red-btn {
  background: $negativeRed;
  border-color: $negativeRed;
  border-radius: 4px;
  color: #ffffff;
  // font-family: "SF Pro Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  width: 225px;
  height: 52px;
}

.deleteLabel {
  color: $negativeRed;

  svg {
    path {
      fill: $negativeRed;
    }
  }
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: $blueAccent;
  border-color: $blueAccent;
}

.sendMessage {
  color: $black;
  display: flex;
  align-items: center;

  svg {
    path {
      fill: $black;
    }
  }
}

//Expandable Search Input
.expandableSearchInput {
  // margin-right: 13px;
  .ant-input-affix-wrapper {
    border-radius: 50px !important;
    height: 40px !important;
    background-color: $paleWhite !important;
    border: 0 !important;
    input {
      background-color: $paleWhite !important;
    }
    .ant-input-prefix {
      margin-inline-end: 0;
      font-size: 18px;
    }
    &.ant-input-affix-wrapper-focused {
      box-shadow: none !important;
    }
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      border-color: $paleWhite;
      cursor: pointer;
    }
  }
  .ant-input-group-addon {
    display: none;
  }
}
