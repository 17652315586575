* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  // font-family: "SF Pro Regular", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif !important;
  // background: #e7e7e7;
  background: white;
  font-size: 16px;
  overflow: hidden;
  line-height: 1;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;

  &:hover {
    color: $blueAccent;
  }
}

p {
  font-size: 14px;
}

body::-webkit-scrollbar {
  // width: 5px;
  display: none;
}

body::-webkit-scrollbar-track {
  background: #f4f4f4;
}

body::-webkit-scrollbar-thumb {
  background: #c4c4c4;
}

body::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}

//Loader
.load {
  width: 24px;
  height: 24px;
  border: solid 4px rgba(2, 2, 2, 0.2);
  margin: 0 auto;
  text-align: center;
  border-radius: 50%;
  //border-right-color: transparent;
  //border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.white {
    border-right-color: $white;
  }

  &.blue {
    border-right-color: $blueAccent;
  }

  &.black {
    border-right-color: $black;
  }
}

.innerContainer {
  padding: 0 45px;
  position: relative;
}

.pLessContainer {
  padding: 0;
}

.pLRLess {
  padding: 22px 0px !important;
}

.outerContainer {
  border-top: 1px solid #e9ebf0;
  padding: 0 0 0 42px;
}

.purple-color {
  color: #7b68ee;
}

.grey-color {
  color: #7f7f88;
}

.small-font {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

::-webkit-scrollbar {
  // width: 5px;
  display: none;
  width: 1px;
}

::-webkit-scrollbar-track {
  background: red;
  width: 1px;
}

::-webkit-scrollbar-thumb {
  background: $lightGray;
  width: 1px;
}

::-webkit-scrollbar-thumb:hover {
  background: $gray2;
}

/*
**********************************************
* css loader circle
**********************************************
*/
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

//Step body focused
.stepFocused {
  position: relative;

  .sidebarItem {
    &:first-child {
      .active {
        border-color: transparent;
        background: transparent;
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 12;
  }
}

.icon-text-wrapper {
  display: flex;
  align-items: center;

  p {
    margin: 0 0 0 12px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
  }
}

.fs-sm {
  font-size: 10px;
  line-height: 24px;
}

.fs-12 {
  font-size: 12px !important;
  line-height: 24px !important;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.fallBackText {
  margin: 15px 0 0 17px;
  font-size: 13px;
  color: #a8a8a8;
}

#portrait-warning {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;

  @media only screen and (max-height: 575.98px) and (orientation: landscape) {
    display: block;
  }
}

.gotoHomepage {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

.ant-picker-dropdown .ant-picker-header {
  color: #7b68ee;
}
.ant-picker-dropdown .ant-picker-header .ant-picker-year-btn:hover {
  color: #7b68ee;
}

.ant-picker-dropdown .ant-picker-header .ant-picker-month-btn:hover {
  color: #7b68ee;
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: #7b68ee;
}
.ant-picker-dropdown .ant-picker-today-btn:hover {
  color: #7b68ee;
}
