.dashboard_state {
  display: flex;
  justify-content: space-between;
  margin: 0px -10px 20px;

  .state_items {
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    height: 126px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    padding-bottom: 5px;

    &:hover {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    }

    &:first-child {
      border-top: 4px solid #6a58d7;
    }

    &:nth-child(2) {
      border-top: 4px solid #f7c444;
    }

    &:nth-child(3) {
      border-top: 4px solid #f82058;
    }

    &:nth-child(4) {
      border-top: 4px solid #00b000;
    }

    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
      color: #000000;
      margin-bottom: 5px;
    }

    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 0px;
      font-style: normal;
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 1px;
      color: #a8a8a8;
    }
  }

  @media screen and (max-width: 1380px) {
    .achieved-goals {
      display: none;
    }
  }
}

.dashboard_request {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 10px 0px 30px 30px;

  .dashboard_listing {
    height: calc(100vh - 372px);
    overflow: auto;

    .ant-spin-nested-loading {
      height: auto;
    }
  }

  .header_dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 75px;
    padding-top: 25px;
    padding-bottom: 10px;

    .heading_class {
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 0px;
      }
    }

    .items_class {
      ul {
        display: flex;
        align-items: center;
        margin-right: 30px;

        li {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #000000;

          &:last-child {
            color: #7b68ee;
            margin-left: 10px;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
  }

  .dashboard_listing {
    .listing_item {
      .ant-table-wrapper {
        display: none;
      }

      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
        margin-top: 30px;
        margin-bottom: 8px;
        cursor: pointer;

        svg {
          margin-right: 5px;
          transform: rotate(270deg);

          path {
            fill: #7f7f88;
          }
        }

        &:hover {
          color: #7b68ee;
        }
      }

      &.active {
        .ant-table-wrapper {
          display: block;
        }

        h4 {
          svg {
            transform: rotate(0deg);

            path {
              fill: #7b68ee;
            }
          }
        }
      }
    }

    .ant-spin-container {
      .ant-table-container {
        .ant-table-content {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                p {
                  font-weight: 600;
                  font-size: 13px;
                  color: #000000;
                  .request-username {
                    font-weight: 600;
                    font-size: 13px;
                    color: #000000;
                    &:hover {
                      color: #7b68ee;
                    }
                  }

                  span {
                    font-size: 11px;
                    color: #a8a8a8;
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dashboard_activity {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 20px 30px;

  .header_dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 15px;
    padding-top: 11px;

    .heading_class {
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 0px;
      }
    }

    .items_class {
      ul {
        display: flex;
        align-items: center;
        margin-right: 0px;

        li {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #000000;

          &:last-child {
            color: #7b68ee;
            margin-left: 10px;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
  }

  .activity_listing {
    height: calc(100vh - 219px);
    overflow: auto;

    .listing_item {
      // margin: 20px 0px;
      margin-top: 5px;
      margin-bottom: 20px;

      .opacity {
        opacity: 0.5;
      }

      .activity_items {
        display: flex;
        border-bottom: 1px solid #f0f0f0;
        padding: 10px 0px;
        align-items: flex-start;
        position: relative;
        .img_box {
          margin-right: 12px;
          cursor: pointer;
          margin-top: 2px;

          .ant-avatar {
            border: unset;
            min-width: 40px;
            height: 40px;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .txt_box {
          flex: 1;

          &.read {
            opacity: 0.5;
          }

          .title {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            color: #000000;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
              color: #7b68ee;
            }
          }

          .unread-circle {
            // position: relative;
            &:after {
              content: "";
              position: absolute;
              width: 10px;
              height: 10px;
              background-color: #7b68ee;
              right: 0;
              bottom: 5px;
              top: 19px;
              border-radius: 50%;
              z-index: 1;
            }
          }

          ul {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            li {
              &:first-child {
                color: #212529;
                font-size: 13px;
                line-height: 18px;
                margin-top: 2px;
                margin-bottom: 2px;
              }

              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 16px;
              color: #a8a8a8;
            }
          }
        }
      }
    }
  }
}
