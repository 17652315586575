.hasValue {
  .customDatePicker {
    height: 100%;
  }
}

.customDatePicker {
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: none;
  width: 100%;
  height: 100%;
  position: relative;
  // z-index: 5555;
  background: transparent;

  &:hover {
    cursor: pointer;
    background: transparent;
  }

  &:active,
  &:focus {
    box-shadow: unset;
  }

  &::placeholder {
    color: $gray2;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
  }

  .ant-picker-input {
    &:hover {
      cursor: pointer;
    }

    input {
      color: $black;
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
      z-index: 50;
      // font-family: "SF Pro Regular";

      &::placeholder {
        color: $gray2;
        font-size: 15px;
        line-height: 18px;
        // font-family: "SF Pro Regular";
        font-weight: 400;
        z-index: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .ant-picker-suffix {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    z-index: 0;
  }

  .ant-picker-clear {
    right: 30%;
    display: none;
  }

  &.customfontsize {
    .ant-picker-input {
      input {
        font-size: 13px;
      }
    }
  }
}
