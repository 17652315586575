.StepActive {
  position: relative;

  .tourBox {
    display: block;
  }
}

.tourBox {
  position: absolute;
  left: 120%;
  bottom: -20px;
  color: black;
  background: white;
  border-radius: 6px;
  min-width: 293px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  min-height: 150px;
  padding: 20px 20px;
  text-align: left;
  display: none;
  z-index: 50;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  &.step1 {
    top: auto;
    left: 120%;
    bottom: -20px;

    .triangle {
      bottom: 20px;
      left: -7%;
    }
  }

  &.step2 {
    top: auto;
    left: 107%;
    bottom: -20px;

    .triangle {
      bottom: 20px;
      left: -7%;
    }
  }

  &.step3 {
    bottom: 0;
    top: -30%;
    left: 107%;

    .triangle {
      bottom: 0;
      top: 17%;
    }
  }

  &.step4 {
    left: 107%;

    //.triangle {
    //  bottom: 0;
    //  top: 17%;
    //}
  }

  &.step5 {
    top: 140%;
    left: -235%;
    width: 296px;

    .triangle {
      top: -8%;
      left: 39%;
      transform: rotate(0deg);
    }
  }
}

.triangle {
  position: absolute;
  bottom: 20px;
  left: -7%;
  display: inline-block;
  width: 37px;
  height: 26px;
  border-style: solid;
  border-width: 0 18.05px 26.29px 18.05px;
  border-color: transparent transparent #ffffff transparent;
  border-radius: 2px;
  transform: matrix(0.01, -1, 1, 0.01, 0, 0);
}

.tourbuttons {
  display: flex;
  flex-wrap: nowrap;

  .btn-primary {
    min-height: auto;
    font-size: 12px;
    min-width: 68px;
  }

  .smallbtn {
    font-size: 10px;
    border: none;
    color: #7f7f88;
    white-space: nowrap;
  }
}
