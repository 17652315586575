.sidebarWrapper {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 233px;
  min-width: 233px;
  height: 100vh;
  position: relative;
  background-image: linear-gradient(
    175.59deg,
    #ff0be5 -60.31%,
    #6845f1 55.39%,
    #7b68ee 75.25%,
    #cb04cf 172.43%,
    #7b68ee 172.63%
  );

  ul.sidebarList {
    height: calc(100vh - 225px);
    overflow: auto;
  }
  ul.sidebarList.sidebarOverflow {
    overflow: visible !important;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 142px;
    height: 180px;
    z-index: 0;
    background-image: url("../../images/ss-white-sidebar-logo 1.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .sidebarHead {
    display: flex;
    justify-content: center;
    padding: 24px 0;
  }

  .sidebarBody {
    flex: 1;
  }

  .sidebarFooter {
    padding: 11px 27px 31px 27px;

    .btn-primary {
      z-index: 4;
    }

    .storageBox {
      margin-bottom: 16px;

      span {
        display: block;
        line-height: 1;
        margin-bottom: 4.5px;
        color: white;
        font-size: 10px;
        a {
          color: white;
        }
      }

      p {
        margin: 0;
        display: flex;
        justify-content: space-between;
        font-size: 9px;
      }

      .ant-progress {
        margin: 0;
        line-height: 0;
        display: block;
        margin-bottom: 4.5px;

        .ant-progress-inner {
          background-color: rgba(255, 255, 255, 0.5);
        }

        .ant-progress-bg {
          background-color: white;
          height: 3.8px !important;
        }

        .ant-progress-outer {
          padding: 0;
          margin: 0;
        }

        .ant-progress-text {
          display: none;
        }
      }
    }
  }
}

//*****************************
//     Sidebar List CSS
//*****************************

.sidebarList {
  @media screen and (max-width: 1366px) {
    height: calc(100vh - 250px);
    overflow: auto;
  }
  .sidebarItem {
    .sidebarText {
      color: white;
      margin: 0;
      margin-left: 20px;
    }

    a {
      background: transparent;
      padding: 16px 24px;
      height: 46px;
      display: flex;
      align-items: center;
      border-left: 5px solid transparent;
      transition: 0.5s ease;
      position: relative;
      z-index: 9;

      // @media (max-width: 1366px) {
      //   height: 30px;
      // }

      .icon {
        width: 18px;
        &.activeIcon {
          display: none;
        }
      }

      &.active {
        border-color: white;
        background: hsla(0, 0%, 100%, 0.2);
        border-left: 5px solid #fff;
        .icon {
          &.activeIcon {
            display: block;
          }

          display: none;
        }
      }
      &:hover {
        border-color: white;
        background: hsla(0, 0%, 100%, 0.1);
        border-left: 5px solid rgb(255, 255, 255, 10%);

        .icon {
          &.activeIcon {
            display: block;
          }

          display: none;
        }
      }
    }
  }
}

//*****************************
//     Inner  CSS
//*****************************
.innerSideBar {
  padding-top: 16px;
}

.sidebarList {
  &.innerSideBarList {
    .sidebarItem {
      .sidebarText {
        color: $gray3;
        font-weight: 400;
      }

      .logout-box {
        display: flex;
        align-items: center;
        padding: 16px 30px;
        height: 46px;
        cursor: pointer;
        position: relative;
        transition: 0.5s ease;
        &:hover {
          background-color: rgba($blueAccent, 0.1);
          &::before {
            border-left: 5px solid #7a3ff0;
            content: "";
            position: absolute;
            width: 5px;
            height: 46px;
            left: 0;
          }
        }
      }

      a {
        &.active {
          .sidebarText {
            color: $blueAccent;
            font-weight: 600;
          }
        }

        &.active,
        &:hover {
          border-color: #7a3ff0;
          background-color: rgba($blueAccent, 0.1);
        }
      }
    }
  }
}
