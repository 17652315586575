.teamHeading {
  p {
    margin: 20px 0 50px 0;
    font-size: 13px;
  }
}

.teamBody {
  // height: calc(100vh - 250px);
  // overflow: auto;
  &.withoutHeader {
    height: calc(100vh - 90px);
    overflow: scroll;
  }

  &.teamGroupCardOnly {
    height: calc(100vh - 280px);
    overflow: auto;

    @media screen and (max-width: 1380px) {
      .row > .col-lg-3 {
        width: 33.3333%;
      }
    }
  }
}

.shoutoutTable {
  * {
    transition: none !important;
  }

  h5 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  h6 {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 8px;
    margin-top: 14px;
  }

  .ant-table-tbody {
    .ant-table-row {
      &:nth-child(even) {
        background: transparent;
      }

      .ant-table-cell {
        //change to 0 due to table alignment issues
        // padding: 33px 16px;
        padding: 33px 0;
        vertical-align: top;
        transition: unset !important;

        .newmessageBox {
          padding-right: 18%;
        }

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          color: #7f7f88;
          margin: 0;
        }
      }
    }
  }
}

//Memebers
.teamTabMainHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  height: 40px;
}

.teamTabSubHead {
  &.memberHeader {
    p {
      margin: 20px 0 2rem 0;
    }
  }

  &.shoutoutHeader {
    p {
      margin: 20px 0 2rem 0;
    }
  }

  p {
    font-size: 13px;
    margin-bottom: 2rem;
  }
}

// This is adjusted margin
.TeamBody {
  margin-left: -12px;
}
