.authLayout {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 992px) {
    height: 100lvh;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .authBackgroundImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: white;

  .gif {
    .ant-image {
      width: 350px;
    }
  }

  &.child-loader {
    height: 75vh !important;

    .gif {
      .ant-image {
        width: 100px;
      }
    }
  }
}

.authBoxText {
  margin-top: 25px;

  a {
    font-size: 14px;
    margin: 0;
  }
}

.authBody {
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    flex: 1;
  }

  @media (max-width: 767px) {
    height: auto;
    flex: 0;
    margin-top: 20px;
    margin-bottom: 20px !important;
  }
}

.authBox {
  position: relative;
  width: 556px;
  min-height: 485px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  padding: 46px 62px;
  background: $white;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.55);
  border-radius: 20px;

  // @media (max-width: 1366px) {
  //   width: 516px;
  //   min-height: 465px;
  //   padding: 26px 42px;
  // }

  &.addLocation {
    justify-content: flex-start;

    form {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 100%;
    }

    .formheight {
      height: 269px;
      overflow: auto;
      // padding-bottom: 20px;
    }

    .locationFooter {
      padding-top: 20px;
    }
  }
}

.goBackBtn {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 5;
  border-radius: 5px;
  transition: 0.2s ease;

  &:hover {
    box-shadow: 3px 2px 5px rgb(153 142 142 / 50%);
    cursor: pointer;
  }
}

.authTopBar {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 38px 47px;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;

  .authTopText {
    display: flex;
    align-items: center;
    color: $white;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
    }

    a {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: $white;
      margin: 0 0 0 10px;
      line-height: 1;
      transition: 0.5s ease-in-out;

      &:hover {
        text-decoration: underline;

        .arrowIcon {
          transform: translate(5px, 0);
        }
      }

      .arrowIcon {
        margin-left: 2px;
        transition: 0.5s ease-in-out;
      }
    }
  }
}

.SignUp {
  .passCodeLine {
    margin-top: 20px;
  }

  .OtpInput-container {
    margin-bottom: 57px;
  }
}

.authHeaderText {
  margin-bottom: 32px;

  h1 {
    color: $black;
    font-weight: 600;
    font-size: 34px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 22px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;

    &.subcribePlan {
      line-height: 1.8;

      a {
        font-weight: 500;
      }
    }
  }

  a {
    font-weight: 600;

    &:hover {
      color: $blueAccent;
    }
  }
}

/*********************************
       Account Setup page
***********************************/
.accountSetup {
  .authHeaderText {
    margin-bottom: 26px;
  }

  .mb-20 {
    margin-bottom: 17px;
  }

  .themeFormGroup {
    .mb-20 {
      margin-bottom: 20px;
    }
  }
}

/*
  Business Detail
*/

.dateFounded {
  position: relative;

  .roundedClendarBtn {
    display: inline;
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translate(0, -50%);
  }

  .date-picker {
    position: absolute;
    top: 0;
    left: 43%;
    transform: translate(-50%, 0);
    right: 0;
    display: inline-block;
    width: auto;
    margin: 0 auto;
    opacity: 0;
  }
}

//Profile
.profile-img-wrapper {
  position: relative;
  margin-right: 10px;
  cursor: pointer;

  .cameraIcon {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .myProfile {
    &__img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 1px solid white;
      margin-right: 10px;
      overflow: hidden;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.05);
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .cameraIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: fit-content;
        height: fit-content;
      }
    }
  }
}

.myProfile {
  &__img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    border: 1px solid white;
    margin-right: 10px;
    overflow: hidden;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.35);
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .cameraIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: fit-content;
      height: fit-content;
    }
  }
}

/***********************

Responsive Auth Screen
 */

@media only screen and (max-width: 700px) {
  .authBox {
    width: 375px;
    height: auto;
    padding: 15px 25px;
    max-width: 375px;
    min-width: 300px;
  }

  .authHeaderText h1 {
    margin-bottom: 12px;
  }

  .authTopBar {
    padding: 10px;
  }

  .authLayout {
    padding: 20px;
  }

  .authBackgroundImg {
    height: 100%;
  }

  .OtpInput-container {
    // width: 50px !important;
    margin-bottom: initial;
  }

  .OtpInput-container .OtpInput input {
    width: auto !important;
  }

  .main-pricing-modal .pricing-modal-content .modal-header {
    background-color: #f8f8f8;
  }

  .main-pricing-modal .pricing-modal-content .modal-header p {
    font-size: 13px;
    line-height: 1.6em;
  }
}

@media only screen and (max-width: 460px) {
  .OtpInput-container .OtpInput input {
    width: 62px !important;
    height: 55px;
    border: 2px solid #cfcfcf !important;
  }

  .authBox {
    width: 350px;
    height: auto;
    padding: 35px 25px;
    max-width: 250px;
    min-width: 350px;
    min-height: auto;
  }

  .resetCode button.w-100.btn-submit.m-t-50.btn.btn-primary {
    margin: 30px 0 0;
  }
}

@media only screen and (max-width: 375px) {
  .OtpInput-container .OtpInput input {
    width: 55px !important;
  }

  .authBox {
    width: 375px;
    height: auto;
    padding: 35px 25px;
    max-width: 250px;
    min-width: 310px;
  }
}

@media only screen and (max-width: 320px) {
  .OtpInput-container .OtpInput input {
    width: 40px !important;
  }

  .authBox {
    width: 364px;
    height: auto;
    padding: 35px 25px;
    max-width: 230px;
    min-width: 270px;
  }
}

@media only screen and (max-width: 300px) {
  .authBox {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .authHeaderText h1 {
    font-size: 20px;
  }

  .authHeaderText p {
    font-size: 13px;
  }

  .BusniessDetailFormInput {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 432px) {
  .businessDetailDropdown.ant-select .ant-select-arrow {
    inset-inline-end: 15px;
  }

  .authHeaderText h1 {
    font-size: 28px;
    line-height: 1;
  }

  .authHeaderText p {
    font-size: 13px;
    line-height: 1;
    display: none;
  }

  .BusniessDetailFormInput {
    margin-bottom: 10px;
  }

  .profile-img-wrapper {
    margin-right: 0;
  }
}

@media only screen and (max-width: 960px) {
  body {
    overflow: unset;
  }

  .authLayout {
    background-size: cover;
  }

  .authTopBar {
    position: relative;
  }

  .authBody {
    margin-bottom: 50px;
  }
}
