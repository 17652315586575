.ant-tree-select {
  &.hasValue {
    .ant-select-arrow {
      margin-top: -7px;
      right: 0;
    }
  }

  .ant-select-arrow {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    transform: translate(0, -50%);
    top: 50%;
    margin: 0;
    right: 0.75rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

//CSS for the custom label
.treeSelect {
  .form-control {
    &.hasValue {
      .customLabel {
        padding: 0;
      }
    }
  }
  .customLabel {
    padding: 0 0.75rem;
  }
}

.ant-tree-select-dropdown {
  .ant-select-tree {
    .ant-select-tree-switcher {
      position: absolute;
      width: 100%;
      z-index: 150;
      margin-left: -4px;
      text-align: right;
    }

    .ant-select-tree-treenode {
      padding: 8px 0;
      width: 100%;
      position: relative;
      .ant-select-tree-node-content-wrapper {
        transition: 0.5s ease;
        background-color: unset;
        padding: 0;

        &.ant-select-tree-node {
          background-color: unset !important;
        }

        &:hover {
          background-color: unset !important;

          .ant-select-tree-title {
            color: $blueAccent;
          }
        }
      }

      .ant-select-tree-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $black;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
}

.ant-tree-select-dropdown {
  .ant-select-tree {
    .ant-select-tree-treenode {
      .ant-select-tree-node-content-wrapper {
      }
    }
  }
}

.ant-select-tree-treenode-selected {
  .roundedIcon {
    position: relative;
    display: block;
    background: $blueAccent;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow:
      0 0 0 3px #ffffff,
      0px 0px 0 4px $blueAccent;
    margin-right: 4px;
    &.check {
      box-shadow: unset;
      width: 17px;
      height: 17px;
      margin-right: 0;
      &:after {
        content: "";
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        display: inline-block;
        height: 8px;
        width: 4px;
        border-bottom: 1px solid white;
        border-right: 1px solid white;
      }
    }
  }
}

.roundedIcon {
  position: relative;
  display: block;
  background: $white;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  box-shadow:
    0 0 0 3px #ffffff,
    0px 0px 0 4px $gray2;
  margin-right: 4px;
}

.image {
  position: absolute;
  bottom: 0;
  left: 0;
}
