.customToolTip {
  position: relative;
}

.customToolTipBox {
  // position: absolute;
  bottom: -30px;
  right: auto;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  min-width: 200px;
  left: 172%;
  transition: 0.5s ease;
  // .triangleToolTip {
  //   position: absolute;
  //   bottom: 20px;
  //   left: -9%;
  // }
  .redheader {
    background-color: #ff0054;
  }
  .greenheader {
    background-color: #00b002;
  }
  .blueheader {
    background: $blueAccent;
  }
  .header {
    padding: 10px 17px;

    border-radius: 5px 5px 0 0;

    h5 {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .body {
    height: 280px;
    overflow: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 3px;
      display: block !important;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-track {
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $lightGray;
    }

    ul {
      li {
        padding: 10px 16px 5px 16px;
        display: flex;
        align-items: center;
        gap: 12px;
        &:first-child {
          padding-top: 15px;
        }
        p {
          font-size: 13px !important;
          font-weight: 500 !important;
        }
      }
    }
  }
}

.isApply {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
}
