.uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  gap: 17px;
  width: 100%;
  height: 248px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: white;

  p {
    color: #7f7f88;
    text-align: center;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin: 0;

    span {
      color: #7b68ee;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .fileFormat {
    font-size: 12px;
  }
}

.ant-upload-select {
  width: 100%;
}

.eventDetailBody .response-card .response-body span {
  font-weight: 600;
}

.small-tags .pro-div .arrows img {
  cursor: pointer;
}

@media only screen and (max-width: 1380px) {
  .uploadBox {
    height: 270px;

    p {
      font-size: 12px;
    }

    .fileFormat {
      font-size: 10px;
    }
  }

  .account__info__drawer .list__container .my__list__group .list-group-item {
    padding: 17px 0px;
    font-size: 14px;
  }

  .leftEventDetailWrapper .eventDetailBody .eventDetailInformation {
    width: 100%;
    word-break: break-all;
  }

  .help_popup {
    height: 520px;
  }

  .help_popup .main_box .help_listing {
    padding: 20px;
  }

  .help_popup .main_box .help_listing .help_listing_box .help_listing_items {
    margin: 10px 0px;
  }

  .help_popup
    .main_box
    .help_listing
    .help_listing_box
    .help_listing_items
    svg {
    margin-bottom: 10px;
  }

  .help_popup .main_box .main_txt p {
    margin-bottom: 10px;
  }

  .sidebarWrapper ul.sidebarList li.sidebarItem a {
    padding: 10px 24px;
    height: 42px;
  }

  .sidebarWrapper ul.sidebarList li.sidebarItem a p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1280px) {
  .dashboardMainWrapper {
    background-color: #fff;
  }

  .dashboardLayoutWrapper .dashboardMainWrapper {
    width: calc(100% - 233px);
  }

  .leftEventDetailWrapper .eventDetailBody .eventDetailInformation {
    width: 100%;
  }
}

@media only screen and (max-width: 1180px) {
  .refferal-row {
    display: block;

    .refferal-container {
      width: 100%;
      margin: 0 0 10px 0;
      padding: 10px 20px;
    }
  }

  .updatePasswordBox,
  .profileEditBox {
    padding: 40px 20px 0;

    .mb-20.custom-textarea.editTextArea.form-floating {
      margin-top: 20px;
    }
  }

  .survey-container .survey-border-wrapper .survey-answer-box {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 1024px) {
  .sidebarList .sidebarItem a {
    padding: 10px 24px;
    height: 30px;
  }

  .sec-heading {
    font-size: 26px;
  }

  .generic-modal .modal-header .modal-title {
    font-size: 28px;
  }

  .updatePasswordBox,
  .profileEditBox {
    .mb-20.custom-textarea.editTextArea.form-floating {
      margin-top: 20px;
    }
  }

  .billingpagewrapper .planbilling .planA1 h3 {
    font-size: 18px;
  }

  .custom-textarea.form-floating label[for="intructions"]:after {
    display: none;
  }

  .globalHeader {
    flex-direction: column;
  }

  .generic-modal .modal-footer .custom-footer-btns {
    display: unset;
  }

  .modal-footer .custom-footer-btns .btn-primary.custom-width-690 {
    width: 100%;
    min-width: 100%;
  }

  .generic-modal .modal-footer .custom-footer-btns .custom-width-332 {
    width: 100% !important;
    min-width: 100%;
    margin: 0 0 5px 0;
  }

  .main-push-alert .main-alert-box {
    padding: 10px;
  }

  .main-push-alert .ant-upload.ant-upload-select svg {
    width: 100%;
  }

  .modal-body
    tabs-modal
    .uploadHeader.d-flex.justify-content-between.align-items-center {
    display: unset;
  }

  .globalHeader--left {
    margin-bottom: 15px;
  }

  .main-create-push-modal .modal-content {
    width: 100%;
  }

  .btn-primary.custom-width-690 {
    width: 100%;
  }

  .help_popup {
    height: 520px;
  }

  .help_popup .main_box .help_listing {
    padding: 20px;
  }

  .help_popup .main_box .help_listing .help_listing_box .help_listing_items {
    margin: 10px 0px;
  }

  .help_popup
    .main_box
    .help_listing
    .help_listing_box
    .help_listing_items
    svg {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
  }

  .help_popup .main_box .main_txt p {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .mobileLadningPage .logo .landingImage {
    bottom: 0;
  }

  .accountSetup .authHeaderText {
    font-size: 28px;
    margin: 0;
  }

  .accountSetup .authHeaderText p {
    display: none;
  }
}
