.all_select.bio .select_option_new {
  .ant-select {
    &:hover {
      .ant-select-selector {
        border-color: #000;
      }
    }
    .ant-select-selector {
      background: #fff;
      border: 1px solid #a29fb159;
      border-radius: 5px;
      height: auto;
      min-height: 58px;
      padding: 0.3rem 0.75rem;
      .ant-select-selection-search {
        margin-inline-start: 0px;
      }
      .ant-select-selection-placeholder {
        position: absolute;
        top: 50%;
        inset-inline-start: 11px;
        inset-inline-end: 11px;
        transform: translateY(-50%);
        transition: all 0.3s;
        text-align: left;
        font-size: 15px;
        color: #a9a9a9;
      }
      .ant-select-selection-overflow {
        position: relative;
        display: flex;
        flex: auto;
        flex-wrap: wrap;
        max-width: 100%;

        .ant-select-selection-overflow-item {
          flex: none;
          align-self: center;
          max-width: 100%;
          display: inline-flex;
          .ant-select-selection-item {
            background: #f1f3f6;
            border: 1px solid #a29fb159;
            border-radius: 4px;
            color: #000;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            height: 44px;
            line-height: 18px;
            padding: 12px;
          }
        }
      }
    }
  }
}
