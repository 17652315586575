h1 {
  font-size: 36px; //Main Heading
}

h2 {
  font-size: 22px;
  font-weight: 600;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

h4 {
  font-size: 15px;
  font-weight: 600;
}

h5 {
  font-size: 14px;
  font-weight: 600;
}

p {
  font-size: 14px;
  font-weight: 400;
}

.sec-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  margin: 0;
  color: #000000;
}

.text__capitalize {
  text-transform: capitalize;
}

.faded__text {
  color: #a8a8a8;
}
