.survey-container {
  .survey-border-wrapper {
    &:not(:first-child) {
      border-top: 1px solid #e9ebf0;
    }

    .survey-answer-box {
      padding-top: 39px;
      padding-bottom: 39px;
      width: 571px;

      .question-box {
        padding-bottom: 21px;

        h3 {
          // font-family: "SF Pro Regular";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: #000000;
          margin-bottom: 0;

          span {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      .answer-box {
        .each-response-div {
          h5 {
            // font-family: "SF Pro Regular";
            font-size: 15px;
            line-height: 18px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 0;
            margin-right: 60px;
            padding-bottom: 2px;
            padding-top: 6px;
          }

          .progress-div {
            display: flex;
            align-items: center;

            .ant-progress-text {
              display: none;
            }

            .ant-progress-outer {
              margin-inline-end: calc(-2em - 8px);
              padding-inline-end: 0;
            }

            .ant-progress-line {
              width: 500px;
            }

            p {
              font-size: 13px;
              margin-bottom: 6px;
              font-weight: 600;

              .percent-views {
                display: flex;
                gap: 5px;
              }

              span {
                cursor: pointer;
                color: #7b68ee;
                font-weight: 400px;
              }
            }
          }

          .text-survey-div {
            p {
              font-size: 14px;
              margin-bottom: 0;
              font-weight: 400;
              padding-bottom: 14px;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}

.survey-details {
  span.show-percent {
    width: 35px;
    display: inline-block;
    cursor: default !important;
  }
}
