#resourceLink {
  &::placeholder {
    color: #a8a8a8;
  }
}

.generic-modal {
  &.resource-modal {
    .modal-header {
      padding: 26px 38px;
    }
  }
}

.resource_box {
  border: 1px solid #ebeced;
  border-radius: 4px;
  margin-bottom: 4px;
  position: relative;
  height: 268px;

  .resource_img {
    border-radius: 4px 4px 0px 0px;
    width: 100%;
    height: 158px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .resource_content {
    padding: 15px 18px 0 18px;
    // position: relative;

    h5 {
      font-size: 15px;
      line-height: 1.4;
      max-width: 295px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #a8a8a8;
      margin-bottom: 0;
    }

    .resourceSparkleDiv {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      color: $gray2;

      p {
        line-height: 1;
      }

      .ant-dropdown-trigger:hover svg path {
        fill: $gray2 !important;
      }
    }
  }

  .sub-div {
    padding: 12px 24px 15px 18px;

    ul {
      display: flex;
      justify-content: space-between;

      li {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        display: flex;
        align-items: center;
        color: #a8a8a8;

        svg {
          margin-right: -8px;
        }

        &.body {
          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }

  &:hover {
    background-color: #f8f9fa;
  }
}

.resources-card-height {
  max-height: calc(100vh - 11rem);
  overflow-y: auto;
  .infinite-scroll-component {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    .col-xxl-3.col-lg-4 {
      padding-top: 18px;
    }
  }
}

// Modal Css
.uploadHeader {
  margin-bottom: 10px;

  .title {
    font-weight: 500;
    font-size: 13px;
  }

  p {
    color: $gray;
    font-size: 12px;
    font-weight: 400;
  }
}

.addLinkBox {
  display: flex;
  align-items: center;
  justify-content: center;

  &.resource-pdf {
    .ant-upload-drag-container {
      margin-top: 0px;
    }
  }

  .ant-upload-wrapper {
    width: 100%;

    .ant-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      min-height: 160px;
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  .ant-upload-drag-container {
    margin-top: 8px;
  }

  p {
    color: $gray;
    font-size: 15px;
  }

  .fileText {
    b {
      font-weight: 500;
      color: $blueAccent;
      text-decoration: underline;
      font-style: italic;
      font-size: 15px;
    }
  }

  .filetypes {
    font-size: 12px;
  }
}

.doc-modal .ant-upload-wrapper .ant-upload {
  min-height: 160px !important;
}

.floatedLabel {
  // border-color: $blueAccent;

  position: relative;

  .form-label {
    position: absolute;
    top: 1px;
    left: 12px;
    font-size: 12px;
    color: $blueAccent;
    padding-bottom: 0;
    margin: 0;
    line-height: 1;
    background: white;
    width: calc(100% - 20px);
    padding: 8px 0 0 0;
  }

  .form-control {
    // border-color: $blueAccent;

    padding-top: calc(
      var(--bs-input-padding-y, 0.1rem) + var(--bs-input-line-height, 1) * 1em
    );
  }

  textarea {
    height: 215px;
  }
}

.scrollmenu {
  height: 100px;

  margin-left: auto;
  margin-right: auto;
}

.scrollmenu ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  white-space: nowrap;
  width: 200px;
}

.scrollmenu li {
  margin-right: 15px;
  float: left;
  font-size: 12px;
  list-style-type: none;
}

.small-tags {
  .pro-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-tag-box {
    width: 693px;
    display: flex;
    gap: 8px;
    overflow-x: scroll;
    cursor: grab;

    .tag-box {
      &:hover {
        border-color: #7b68ee;
      }

      &.inactive {
        opacity: 50%;
      }

      &.active {
        background-color: $blueAccent;
        border: 1px solid #7b68ee;
        svg {
          path {
            fill: white;
          }
        }

        p {
          color: $white;
        }
      }

      background-color: #f1f3f6;
      border-radius: 100px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-user-select: none;
      user-select: none;
      white-space: nowrap;
      padding-left: 10px;
      padding-right: 10px;

      p {
        margin-bottom: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
          display: block;
        }
      }
    }
  }
}

.new-small-tags {
  .pro-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-tag-box {
    width: 100%;
    display: flex;
    gap: 8px;
    cursor: grab;
    flex-wrap: wrap;
    cursor: grab;

    .tag-box {
      &.active {
        background-color: $blueAccent;

        svg {
          path {
            fill: white;
          }
        }

        p {
          color: $white;
        }
      }

      border: 1px solid rgba(162, 159, 177, 0.35);
      border-radius: 100px;
      // min-width: 110px;
      height: 30px;
      // width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      white-space: nowrap;
      padding-left: 10px;
      padding-right: 10px;

      p {
        margin-bottom: 0;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
          display: block;
        }
      }
    }
  }
}

//Resource Textarea
.resourcesTextArea {
  &.activeTag {
    .form-label-group {
      border-color: $blueAccent;

      .form-label {
        font-size: 12px;
        color: $blueAccent;
      }
    }

    textarea {
      display: block;
    }
  }

  .form-label-group {
    // border: 1px solid $lightGray;
    border-radius: 6px;
    min-height: 215px;

    .form-label {
      left: 15px;
      padding-bottom: 0;
      font-size: 15px;
      color: $gray2;
      padding-top: 12px;
    }
  }

  textarea {
    // display: none;
    &::placeholder {
      font-size: 15px;
      color: $lightGray;
    }

    &.form-control {
      padding-top: calc(1rem + 9px);
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      // border-color: transparent;
    }
  }
}

// -------------------------------- Resource Modal List  --------------------------------

.resourceSelctLabel {
  h4 {
    margin-bottom: 0;
    font-weight: 400 !important;
  }

  p,
  .roundedIcon {
    display: none;
  }
}

.resourceSelectMenu {
  p,
  .roundedIcon {
    display: block;
  }
}

.resourceSelectMenu {
  padding: 0;

  .resourceSelctLabel h4 {
    margin-bottom: 2px;
    font-weight: 400;
  }

  .resourceSelctLabel .dollar {
    font-weight: 400;
    margin-bottom: 2px;
  }

  &.repeatGoal {
    .ant-select-item {
      padding: 10px 22px;
    }
  }

  .ant-select-item {
    padding: 14px 22px;
    border-bottom: 1px solid #e9ebf0;
    border-radius: 0;

    p {
      font-size: 10px;
    }

    .dollar-p {
      font-size: 11px;
    }
  }
}

.dateSelectMenu {
  p,
  .roundedIcon {
    display: block;
  }
}

.dateSelectMenu {
  padding: 0;

  .resourceSelctLabel h4 {
    font-size: 15px;
    font-weight: 400;
  }

  .ant-select-item {
    padding: 14px 22px;

    border-radius: 0;

    p {
      font-size: 10px;
    }
  }
}

.para-div {
  border-top: 1px solid #dee2e6;
  margin-top: 36px;
  padding-top: 29px;
  display: flex;
  align-items: flex-end;
  gap: 9px;

  svg {
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: -3px;
  }
}

.goalModalWrap {
  &.second-row {
    margin-top: 25px;
  }
}

.for1month {
  .ant-select-item {
    padding: 10px 22px;
  }
}

.custom-upload {
  .ant-upload {
    &.ant-upload-select {
      span {
        svg {
          height: 259px;
        }
      }
    }
  }
}
