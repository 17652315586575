.ant-drawer {
  .ant-drawer-mask {
    background: rgba(0, 0, 0, 0.75);
  }
}

.ant-drawer-content-wrapper {
  // width: 424px !important;
  width: 395px !important;
  .ant-drawer-header {
    border: 0;
    padding: 16px 33px 15px;
    .ant-drawer-title {
      font-size: 32px;
      font-weight: 600;
    }
  }

  .ant-drawer-body {
    padding-top: 0;
  }
}

.drawerExtras {
  display: flex;
  align-items: center;

  li {
    border-right: 1px solid $gray2;
    padding: 0 10px;

    &:last-child {
      border-right: 0;
      padding-right: 0;
    }
  }
}

//Drawer Tabs styling
.drawerTabs {
  .ant-tabs-top {
    line-height: 1.2;
  }

  .ant-tabs .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav {
    width: 340px;
    height: 44px;
    background: $globalBg;
    border-radius: 6px;
    margin: 0 auto;
    margin-bottom: 12px;

    &:before {
      content: normal;
    }
  }

  .ant-tabs-nav-list {
    border: 0;
    margin: 0;
    width: 100%;
    padding: 5px;

    .ant-tabs-tab {
      background: transparent;
      padding: 0;
      width: 50%;
      text-align: center;
      display: flex;
      justify-content: center;

      .ant-tabs-tab-btn {
        color: $gray;
        font-weight: 600;
      }

      &.ant-tabs-tab-active {
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .ant-tabs-tab-btn {
          color: $blueAccent;
        }
      }

      + .ant-tabs-tab {
        margin: 0;
      }
    }
  }
}
