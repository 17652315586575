.iconTextCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(162, 159, 177, 0.35);
  border-radius: 5px;
  padding: 20px 55px 26px;
  text-align: center;
  transition: 0.5s ease;

  svg {
    path {
      transition: 0.5s ease;
    }
  }

  &.active,
  &:hover {
    border-color: $blueAccent;
    cursor: pointer;

    svg {
      path {
        fill: $blueAccent;
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: $bg;
    border-radius: 100%;
    margin-bottom: 14px;
  }

  .heading {
    font-size: 16px;
    color: $black;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .content {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #a8a8a8;
  }
}
