.customTablist {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-top: 4px;

  .customTabItem {
    border-right: 1px solid $lightGray;
    padding-right: 17px;
    margin-right: 17px;

    &:hover {
      cursor: pointer;
      color: #7b68ee;

      // .icon {
      //   svg {
      //     path {
      //       fill: #7b68ee
      //     }
      //   }
      // }
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;
      border-right: 0;
    }

    .tab {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;

      display: flex;
      align-items: center;

      svg {
        margin-right: 9px;

        path {
          fill: $black;
        }
      }

      .icon {
        display: block;

        &.icon-active {
          display: none;
        }
      }

      &.active {
        color: $blueAccent;

        .icon {
          display: none;

          &.icon-active {
            display: block;
          }
        }

        svg {
          path {
            fill: $blueAccent;
          }
        }
      }
    }
  }

  .customScoreItem {
    padding-right: 20px;
    margin-right: 20px;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
      border-right: 0;
    }

    .score {
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
      display: flex;
      align-items: center;
      gap: 7px;

      .icon {
        display: block;
      }
    }
  }
}
