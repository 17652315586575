.memberDetailHeader {
  padding: 13px 43px;
  align-items: center;

  .sec-heading {
    font-size: 20px;
  }
}

.leftMemberDetailWrapper {
  .memberDetailHeader {
    padding: 13px 43px 32px 0;
  }
}

.backToMembers {
  a {
    color: $blueAccent;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg {
      margin-right: 6px;
      width: 16px;
      height: 16px;
    }
  }
}

.main-member-body {
  .member-info {
    display: flex;
    align-items: center;
    gap: 38px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e9ebf0;
    padding-bottom: 30px;

    .avatar {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .member-name {
      h3 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        color: $black;
        padding-bottom: 9px;
      }

      p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #6c757d;
      }
    }
  }

  .main-bio {
    border-top: 0.5px solid #cfcfcf;
    padding-top: 32px;
    margin-bottom: 34px;

    h2 {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: $black;
      padding-bottom: 12px;
    }

    p {
      margin-bottom: 0;
      color: $black;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.5em;
    }
  }

  .main-member-detail {
    display: flex;
    justify-content: space-between;
    padding-right: 90px;
    margin-bottom: 10px;

    .left-detail {
      display: flex;

      .main-first-list {
        width: 103px;

        li {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          color: $black;
          padding-bottom: 30px;
        }
      }

      .main-second-list {
        li {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: $black;
          padding-bottom: 30px;
          min-height: 43px;
          &:last-child {
            min-height: unset;
            padding-bottom: 0;
          }
        }
        .form-control-dropdown {
          position: relative;
        }
      }
    }

    .right-detail {
      .sub-div {
        display: flex;
        height: 20px;
        margin-bottom: 22px;
        width: 303px;
        justify-content: space-between;
        align-items: center;

        h5 {
          margin-bottom: 0;
          font-size: 13px;
          span {
            margin-right: 11px;
          }
        }

        p {
          margin-bottom: 0;
        }
      }

      // .main-first-list {
      //   width: 146px;

      //     li {
      //       font-style: normal;
      //       font-weight: 600;
      //       font-size: 13px;
      //       color: $black;
      //       padding-bottom: 20px;
      //     }

      // }
      // .main-second-list {

      //     li {
      //       font-style: normal;
      //       font-weight: 400;
      //       font-size: 13px;
      //       color: $black;
      //       padding-bottom: 20px;
      //     }

      // }
    }
  }

  .main-badges {
    border-top: 0.5px solid #cfcfcf;
    padding-top: 32px;

    h2 {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: $black;
      padding-bottom: 26px;
    }

    .badges-div {
      display: flex;
      gap: 14px;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;

      img {
        margin-right: 14px;
      }
    }
  }
}

.custom-drawer {
  .edit-profile-title {
    font-size: 24px;
    text-align: center;
  }
  &.editsch {
    .ant-drawer-wrapper-body {
      .ant-drawer-body {
        padding: 0;
      }
    }
  }

  .ant-drawer-header {
    background: linear-gradient(#7b68ee 0%, rgba(137, 32, 255, 0.95) 100%);
  }

  .ant-drawer-header .ant-drawer-title {
    color: white;
    text-align: center;
    padding-left: 20px;
  }

  .ant-drawer-content-wrapper .ant-drawer-header .ant-drawer-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  .schedule-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .schedule-date {
    font-size: 15px;
    font-weight: 600;
  }

  .save-button-container {
    margin-top: 30px;
  }
  .ant-tabs-tab-active {
    box-shadow: none;
    border-radius: 5px;
  }
  .ant-drawer-wrapper-body:after {
    background: none;
    content: normal;
  }

  .scheduleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f8fa;
    padding: 11px 21px 12px 18px;
    border-radius: 5px;
    .dayBox {
      h4 {
        font-size: 14px;
        font-weight: 510;
        margin-bottom: 0;
        padding-bottom: 5px;
      }
      h6 {
        font-size: 12px;
        font-weight: 510;
        margin-bottom: 0;
        color: #7b68ee;
      }
      .off {
        color: #7f7f88 !important;
      }
    }
    .switchBox {
      .ant-switch {
        min-width: 36px;
        height: 22px;
      }
      .ant-switch.ant-switch-checked .ant-switch-handle {
        inset-inline-start: calc(100% - 21px);
      }
      .ant-switch .ant-switch-handle::before {
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        bottom: 0;
        inset-inline-start: 0;
        background-color: #fff;
        border-radius: 9px;
        box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
        transition: all 0.2s ease-in-out;
        content: "";
        width: 19px;
        height: 19px;
      }
    }
  }
}

.memberDropdownBox {
  .form-control-dropdown {
    position: relative;
    top: auto;
    right: auto;
    transform: unset;
    .ant-select-selector {
      height: 12px;
    }
  }
}
