.goalsCard {
  height: 210px;
  border-radius: 5px;
  padding: 17px 15px;
  border: 1px solid $grayborder;
  border-top: 6px solid rgba($blueAccent, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s ease;

  &:hover {
    border-top: 6px solid rgba($blueAccent, 1);
    cursor: pointer;
    box-shadow: 1px 1px 8px 8px rgb(197 181 181 / 8%);

    .goalsHeader {
      .icon {
        opacity: 1;
      }
    }

    .price {
      color: $blueAccent;
    }
  }

  .goalsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
      color: $gray2;
      font-size: 11px;
    }

    .icon {
      opacity: 0.6;
      transition: 0.5s ease;
    }
  }

  .goalsBody {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-bottom: 6px;
    min-height: 40px;
    max-height: 140px;
    height: 180px;

    .ant-avatar {
      background: none;
    }

    .ant-avatar.ant-avatar-icon {
      margin-bottom: 10px;
    }

    .heading {
      font-weight: 600;
      font-size: 16px;
      color: $black;
      padding-top: 10px;
    }

    .price {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      font-weight: 600;
      transition: 0.5s ease;
      color: $blueAccent;
    }
  }

  .goalsFooter {
    p {
      margin: 0;
      color: $gray2;
      font-size: 11px;
      margin-bottom: 2px !important;
    }
  }

  &.achievedCard {
    border-top-color: $green;

    .price {
      color: $green;
    }
  }

  &.missedCard {
    border-top-color: $negativeRed;

    .price {
      color: $negativeRed;
    }
  }
}

.goalPage {
  .globalHeader {
    padding: 20px 0 25px;
    border-bottom: 1px solid #dee2e6;
  }

  .content {
    .col-xxl-3.col-lg-4 {
      padding-top: 18px;
    }
  }
}

.content {
  border-top: unset;
  padding-top: 0;
}

.goal-modal-form {
  .modal-body.tabs-modal {
    overflow: visible;
  }
}

div.col-lg-3 {
  padding-top: 20px;
}