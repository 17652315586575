.help_popup {
  position: absolute;
  bottom: 20px;
  left: 260px;
  background: rgb(123, 104, 238);
  background: linear-gradient(
    180deg,
    rgba(139, 77, 219, 1) 0%,
    rgba(123, 104, 238, 1) 35%,
    rgba(241, 243, 246, 1) 35%
  );
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  height: 660px;
  width: 414px;
  display: none;
  z-index: 99;

  &.active {
    display: block;
  }

  .main_box {
    padding: 20px 30px;

    .main_logo {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        height: 25px;
        width: auto;
      }

      span {
        cursor: pointer;

        svg {
          width: 14px;
          height: 14px;

          path {
            fill: $white;
          }
        }
      }
    }

    .main_txt {
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 18px;
        color: #ffffff;
        margin-bottom: 20px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        margin-bottom: 30px;
      }
    }

    .help_listing {
      background: #ffffff;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 40px 22px;
      a {
        color: #7b68ee;
      }
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 18px;
        color: $black;
        margin-bottom: 10px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $black;
        margin-bottom: 30px;
      }

      .help_listing_box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .help_listing_items {
          flex: 0 0 33.33%;
          text-align: center;
          margin: 20px 0px;

          svg {
            width: 40px;
            height: 30px;
            margin-bottom: 20px;
          }

          h5 {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
            margin: 0px;
          }
        }
      }
    }
  }
}
