.req-heading-div {
  display: flex;
  gap: 30px;
}
.ant-select-item-option-disabled {
  opacity: 25%;
}
//Request detail
.main-request-detail {
  .main-left-div {
    padding: 34px 45px 0 42px;
    border-right: 1px solid #e9ebf0;
    .request-user {
      display: flex;
      align-items: center;
      margin-top: 34px;
      gap: 16px;
      border-bottom: 1px solid #e9ebf0;
      padding-bottom: 24px;

      .avatar-div {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      h2 {
        margin-bottom: 0;
      }
    }
    .user-details {
      margin-top: 15px;
      border-bottom: 1px solid #e9ebf0;
      padding-bottom: 28px;
      margin-bottom: 35px;
      .sub-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      h6 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 40px;
      }
      p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 40px;
      }
    }
    .main-note {
      .downloaddiv {
        span {
          font-size: 12px;
          color: #7b68ee;
          margin-right: 10px;
        }
      }
      h6 {
        font-weight: 700;
        font-size: 13px;
        line-height: 26px;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        padding-bottom: 18px;
      }
    }
  }
  .main-border-div {
    .main-right-div {
      padding: 32px 44px 0 45px;
      background: rgba(245, 246, 248, 0.35);
      .request-detail-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 67px;
        h2 {
          margin-bottom: 0;
        }
        .print-div {
          span {
            margin-left: 10px;
          }
        }
      }
      .req-detail-table {
        &.ant-table-wrapper {
          .ant-table {
            background-color: transparent;
            .ant-table-thead {
              .ant-table-cell {
                font-size: 12px;
                font-weight: bold;
              }
            }
            .ant-table-tbody {
              .ant-table-row {
                .ant-table-cell {
                  padding-top: 24px;
                  padding-bottom: 22px;
                  font-size: 12px;
                }
              }
              .ant-table-row:nth-child(even) {
                background-color: transparent;
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
