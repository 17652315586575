// @font-face {
//   font-family: 'SF Pro';
//   src: url('../../font/sf-pro.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'SF Pro';
//   src: url('../../font/sp-pro-italic.ttf') format('truetype');
//   font-weight: normal;
//   font-style: italic;
// }

@font-face {
  font-family: "SF Pro Bold";
  src:
    url("../../font/SFProDisplay-Bold.woff2") format("woff2"),
    url("../../font/SFProDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Medium";
  src:
    url("../../font/SFProDisplay-Medium.woff2") format("woff2"),
    url("../../font/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro LightItalic";
  src:
    url("../../font/SFProDisplay-LightItalic.woff2") format("woff2"),
    url("../../font/SFProDisplay-LightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro ThinItalic";
  src:
    url("../../font/SFProDisplay-ThinItalic.woff2") format("woff2"),
    url("../../font/SFProDisplay-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro SemiBoldItalic";
  src:
    url("../../font/SFProDisplay-SemiboldItalic.woff2") format("woff2"),
    url("../../font/SFProDisplay-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Regular";
  src:
    url("../../font/SFProDisplay-Regular.woff2") format("woff2"),
    url("../../font/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro";
  src:
    url("../../font/SFProDisplay-Regular.woff2") format("woff2"),
    url("../../font/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro lightItalic";
  src:
    url("../../font/SFProDisplay-UltralightItalic.woff2") format("woff2"),
    url("../../font/SFProDisplay-UltralightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
