.task__container {
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 1px solid #cfcfcf !important;
    // background: red;
  }
}

.task__container_empty {
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: none !important;
    // background: red;
  }
}
.emptyTask__main {
  padding-top: 66px;
  p {
    font-weight: 600;
    color: #000000;
    font-size: 17px;
    padding-top: 66px;
  }
  p:last-child {
    color: #000000;
    font-size: 13px;
    text-align: center;
    padding-top: 0px;
  }
}
