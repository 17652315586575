.quiz-container {
  .quiz-border-wrapper {
    &:not(:first-child) {
      border-top: 1px solid #e9ebf0;
    }

    .quiz-answer-box {
      padding-top: 39px;
      padding-bottom: 39px;
      width: 571px;

      .question-box {
        h3 {
          // font-family: "SF Pro Regular";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;

          margin-bottom: 25px;
          color: #000000;
          span {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      .answer-box {
        h5 {
          // font-family: "SF Pro";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 0;
          padding-bottom: 8px;
        }
        .ant-progress .ant-progress-inner {
          height: 12px;
        }
        .ant-progress-bg {
          height: 12px !important;
        }
        .progress-div {
          display: flex;
          align-items: center;
          .ant-progress-text {
            display: none;
          }
          .ant-progress-outer {
            margin-inline-end: calc(-2em - 8px);
            padding-inline-end: 0;
          }
          .ant-progress-line {
            width: 500px;
          }

          p {
            font-size: 12px;
            margin-bottom: 0;
            font-weight: 600;
          }
          span {
            cursor: pointer;
            color: #7b68ee;
            font-weight: 400;
            font-size: 12px;
            display: block;
          }
        }
      }
    }
  }
}
