.profile-notify-bar {
  display: flex;
  justify-content: space-around;
  border: 1px solid rgba(162, 159, 177, 0.35);
  border-radius: 5px;
  align-items: center;
  width: 280px;
  height: 50px;
}
.profile-notify-bar p {
  color: #7f7f88;
  font-size: 13px;
  font-weight: 500;
  margin-top: 15px;
}
.profile-details {
  display: flex;
  .profile-title {
    font-size: 30px;
    font-weight: 600;
    color: black;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .profile-desc {
    color: #7f7f88;
    font-size: 12px;
    margin-bottom: 0;
  }
  img {
    width: 64px;
    height: 64px;
  }
}
.divider-profile {
  .ant-divider-horizontal {
    margin: 0;
  }
}
.edit-profile-section {
  border-left: 1px solid #e9ebf0;
  padding-left: 61px;
  padding-top: 43px;
}
.password-edit-section {
  padding-top: 43px;
  padding-right: 61px;
}

//Profile CSS
.profileHead {
  padding: 21px 44px 24px 44px;
  border-bottom: 1px solid $paleGray;
}

// Update profile information
.profileEditBox {
  padding: 41px 50px;
  border-left: 1px solid $paleGray;
  height: 100%;
  .form-floating {
    .form-control {
      font-size: 14px;
      height: calc(3.3rem + -2px);
    }

    label {
      font-size: 12px;
      padding: 1.2rem 0.75rem;
      line-height: 1;
    }
  }
  button {
    height: 50px;
  }
}

//Update profile password
.updatePasswordBox {
  padding: 41px 51px 0 46px;
  .form-floating {
    .form-control {
      font-size: 13px;
      height: calc(3.3rem + -2px);
    }

    label {
      font-size: 12px;
      padding: 1.2rem 0.75rem;
      line-height: 1.7;
    }
  }
  button {
    height: 50px;
  }
}
