.searchBar {
  margin-right: 17px;
}

.searchBarWrapper {
  display: flex;
  align-items: center;
  background: $paleWhite;
  // width: 750px;
  height: 42px;
  border-radius: 50px;
  padding: 15px;
  @media (max-width: 1400px) {
    // width: 630px;
  }

  .searchIcon {
    margin-right: 15px;
  }

  .searchInput {
    border: 0;
    background: transparent;
    width: 100%;

    &::placeholder {
      color: rgba(168, 168, 168, 0.8);
      font-size: 12px;
    }

    &:hover,
    &:focus {
      box-shadow: none;
      border: 0;
      outline: none;
    }
  }

  .closeCrossBtn {
    svg {
      display: block;
      height: 20px;
      width: 20px;

      path {
        fill: $gray2;
      }
    }
  }
}

//Class added on the body to make it black
.searchBarFocused {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    // z-index: 26;
    z-index: 9;
  }
}

//Menu List
.searchbarMenuList {
  height: 145px;
  overflow: auto;

  .searchbarMenuItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;

      a {
        color: $blueAccent;
      }
    }

    a {
      color: $gray2;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      flex: 1;
    }
  }
}

//Menu Dropdown
.generalSearchBar {
  position: relative;

  &.active {
    z-index: 555;
    position: relative;
  }

  .menuWrapper {
    position: absolute;
    background: $white;
    width: 100%;
    top: 120%;
    box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
    border-radius: 8px;
    padding: 20px;

    .menuheader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      h4 {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        margin: 0;
      }

      span {
        color: $negativeRed;
        font-size: 12px;
        line-height: 1;
      }
    }

    .noDataImg {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      svg {
        width: 214px;
        height: 124px;
      }
    }
  }
}
